/**=====================
     62. Responsive CSS Start
==========================**/
/* ========= min and max scss for 1200 to 1366 screen ========= */
@media screen and (max-width: 1800px) {
  .cart .qty-box {
    width: 28%; } }

@media screen and (max-width: 1660px) {
  .photos ul li {
    width: 26%; }
  .vertical-menu-main .mega-menu {
    width: 1200px !important;
    max-width: 1200px !important;
    left: 0px !important; }
  .user-status table thead tr th:nth-child(3) {
    min-width: 124px; }
  .user-status table tbody tr td:first-child {
    min-width: 278px; }
  .bar-chart canvas {
    width: 100% !important; }
  .chat-box .chat-history .call-icons ul li {
    border: 1px solid #898989; }
  .chat-box .chat-history .total-time h2 {
    color: #898989; }
  .chat-box .chat-history .call-content .btn {
    width: auto;
    margin: 0 auto; }
  .chat-left-aside .people-list {
    height: 520px; }
  .chat-box .chat-right-aside .chat .chat-msg-box {
    height: 440px; }
  .chat-box .people-list ul {
    max-height: 450px;
    overflow: auto; }
  .call-chat-body .chat-box {
    overflow: hidden; }
    .call-chat-body .chat-box .people-list ul {
      max-height: 550px;
      overflow: auto; }
  .caller-img {
    position: absolute !important;
    width: 100%;
    max-width: 100%;
    left: 15px; }
    .caller-img img {
      opacity: 0.7; }
  .chat-box .chat-history .receiver-img {
    margin-top: 25px; }
  .chat-box .chat-history .call-content > div {
    height: 545px;
    z-index: 9;
    background-color: rgba(255, 255, 255, 0.75);
    background-blend-mode: overlay;
    width: 100%;
    padding: 30px; } }

@media screen and (max-width: 1550px) {
  .bg-gallery {
    height: 90vh; } }

@media screen and (max-width: 1440px) and (min-width: 1366px) {
  .cal-info {
    width: 148px; } }

@media screen and (max-width: 1440px) {
  .bg-gallery {
    height: 85vh;
    min-height: 700px; }
  .landing_gallery_section_img:before {
    content: '';
    height: 300px;
    background: -webkit-gradient(linear, left bottom, left top, color-stop(8%, #002c61), color-stop(92%, transparent));
    background: linear-gradient(to top, #01397d 8%, transparent 92%); } }

@media (max-width: 1280px) {
  .bg-gallery {
    height: 108vh; }
  .img-gallery-set1, .img-gallery-set5 {
    display: none; }
  .box-layout.page-wrapper .page-main-header {
    padding-left: unset;
    max-width: unset;
    left: unset; }
  .box-layout.page-wrapper .page-body-wrapper {
    width: unset; }
    .box-layout.page-wrapper .page-body-wrapper .page-sidebar.open ~ footer {
      width: unset; }
    .box-layout.page-wrapper .page-body-wrapper .footer {
      margin-left: unset;
      width: unset; } }

@media screen and (max-width: 1580px) and (min-width: 1200px) {
  .docs-buttons, .docs-toggles {
    flex: 0 0 100%;
    max-width: 100%; } }

@media screen and (min-width: 1200px) and (max-width: 1366px) {
  .chart-widget-top h5 {
    font-size: 19px; }
  .status-details span {
    font-size: 13px; }
  .status-details h4 span {
    font-size: 24px; }
  .product-wrapper-grid.list-view .row .xl-4 {
    max-width: 100%;
    flex: 0 0 100%; }
  .vertical-menu-mt .crm-activity {
    height: 298px; }
  .flot-chart-container-small {
    height: 227px; }
  .call-chat-sidebar {
    max-width: 260px; }
  .product-box .product-details {
    padding: 15px; }
    .product-box .product-details p {
      margin-bottom: 10px; }
  .smooth-chart.flot-chart-container, .flot-chart-container {
    height: 282px; }
  .custom-card .card-profile img {
    height: 115px; }
  .custom-card .profile-details h4 {
    font-size: 18px; }
  .custom-card .profile-details h6 {
    margin-bottom: 24px; }
  .custom-card .card-footer > div h3 {
    font-size: 18px; }
  .xl-none {
    display: none; }
  .xl-60 {
    max-width: 60%;
    flex: 0 0 60%; }
  .xl-50 {
    max-width: 50%;
    flex: 0 0 50%; }
  .xl-100 {
    max-width: 100%;
    flex: 0 0 100%; }
  .xl-23 {
    max-width: 23%;
    flex: 0 0 23%; }
  .xl-25 {
    max-width: 25%;
    flex: 0 0 25%; }
  .xl-40 {
    max-width: 40%;
    flex: 0 0 40%; }
  .xl-4 {
    max-width: 33.33%;
    flex: 0 0 33.33%; }
  .crm-activity {
    height: 291px;
    overflow-y: auto; }
    .crm-activity .media .media-body .dates {
      display: flex; }
  .calender-widget .cal-date {
    width: 95px;
    height: 95px; }
    .calender-widget .cal-date h5 {
      line-height: 1.4;
      padding: 13px; }
  .chat-body {
    padding: 10px; }
  .chat-box .chat-history .call-content .btn {
    font-size: 15px; }
  .chat-box .chat-history .total-time h2 {
    font-size: 30px; }
  .chat-box .chat-history .receiver-img {
    margin-top: 30px;
    margin-bottom: 30px; }
  .chat-box .chat-history .call-icons {
    margin-top: 20px;
    margin-bottom: 20px; }
    .chat-box .chat-history .call-icons ul li {
      width: 50px;
      height: 50px;
      padding: 7px; }
  .chat-box .user-image {
    width: 30px;
    height: 30px;
    margin-top: 3px;
    margin-right: 3px; }
  .chat-box .about {
    margin-top: 0; }
  .chat-box .border-tab.nav-tabs .nav-item a {
    padding: 13px 4px !important;
    color: #aaaaaa !important;
    letter-spacing: 0;
    font-size: 12px !important;
    line-height: 6; }
  .chat-box .chat-menu .people-list ul.list {
    max-height: 376px;
    overflow-y: auto; }
  .chat-box .chat-menu .user-profile .image .avatar img {
    width: 100px;
    height: 100px; }
  .chat-box .chat-menu .user-profile .image .icon-wrapper {
    width: 30px;
    height: 30px;
    font-size: 13px; }
  .chat-left-aside .people-list {
    height: 420px; }
    .chat-left-aside .people-list ul {
      max-height: 376px;
      overflow-y: auto; }
  .chat-left-aside .status-circle {
    top: 25px;
    left: 22px; }
  .blog-box .blog-details-main .blog-bottom-details {
    margin: 20px 10px; }
  .blog-box .blog-details-main .blog-social li {
    padding: 0px 10px; }
  .blog-bottom-content {
    display: none; }
  .blog-box.blog-shadow .blog-details {
    padding: 10px; }
  .browser-widget img {
    height: 50px; }
  .email-wrap .email-left-aside .email-app-sidebar .media {
    display: block; }
    .email-wrap .email-left-aside .email-app-sidebar .media .media-size-email {
      width: 100%;
      text-align: center; }
      .email-wrap .email-left-aside .email-app-sidebar .media .media-size-email .mr-3 {
        margin-right: 0 !important; }
    .email-wrap .email-left-aside .email-app-sidebar .media .media-body {
      text-align: center; }
  .email-wrap .email-right-aside .email-body .attachment ul li img {
    width: 73px;
    height: 73px; }
  .email-wrap .email-right-aside .email-body .email-top {
    line-height: 50px; }
    .email-wrap .email-right-aside .email-body .email-top .float-right {
      display: none !important; }
    .email-wrap .email-right-aside .email-body .email-top h5 {
      padding: 13px 0; }
  .email-wrap .email-right-aside .email-body .inbox {
    max-height: 668px;
    overflow: auto; }
    .email-wrap .email-right-aside .email-body .inbox .media {
      display: block; }
      .email-wrap .email-right-aside .email-body .inbox .media .media-size-email {
        width: 100%;
        text-align: center; }
        .email-wrap .email-right-aside .email-body .inbox .media .media-size-email .mr-3 {
          margin-right: 0 !important; }
    .email-wrap .email-right-aside .email-body .inbox .media-body {
      text-align: center; }
      .email-wrap .email-right-aside .email-body .inbox .media-body h6 {
        font-size: 14px; }
  .email-wrap .email-right-aside .email-body .email-compose .cke_contents.cke_reset {
    max-height: 144px;
    width: 99%; }
  .email-wrap .email-right-aside .email-body .email-compose .compose-border {
    margin: 0; }
  .alert-dismissible p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 181px; }
  .bootstrap-touchspin .touchspin-vertical-tab .input-group {
    width: 96% !important; } }

/* ========= responsive scss for 1366 screen ========= */
@media only screen and (max-width: 1366px) {
  .flot-chart-container #toggling-series-flot {
    width: 71% !important; }
  .landing-center .img-wave2-set {
    bottom: 0;
    height: 13%; }
  .page-wrapper .page-body-wrapper .page-header .row h3 {
    font-size: 22px; }
  .footer-bg .title img {
    height: 100px; }
  .feature-img2, .feature-img1 {
    display: none; }
  .landing-center .landing_first_section_img {
    width: 85%; }
  .img-wave1-set {
    bottom: 20px;
    height: 22%; }
  .bg-sky-blue {
    height: 1040px; }
  .cart .qty-box {
    width: 40%; }
  .grid-options ul li:last-child {
    display: none; }
  .product-wrapper .product-grid .feature-products {
    margin-left: 0; }
  .product-wrapper-grid.list-view .product-box .product-img {
    width: 25%; }
  .timeline-small .media .media-body p {
    width: 95%; }
  .map-js-height {
    height: 350px; }
  .chat-box .chat-right-aside .chat .chat-msg-box {
    height: 364px; }
    .chat-box .chat-right-aside .chat .chat-msg-box .chat-user-img {
      margin-top: -30px; }
    .chat-box .chat-right-aside .chat .chat-msg-box .message {
      width: 100%;
      padding: 15px; }
  .chat-right-aside {
    flex: 0 0 60%;
    max-width: 60%;
    overflow: hidden; }
    .chat-right-aside .chat .chat-message .smiley-box {
      width: 45px; }
  .chat-box .chat-history .receiver-img img {
    height: 150px; }
  .call-chat-body .chat-box {
    overflow: hidden;
    height: 565px; }
  .calender-widget .cal-desc p {
    max-height: 88px;
    overflow: auto; }
  .equal-height-xl {
    height: 517px; }
  .authentication-main .auth-innerright {
    background-size: cover; }
  .grp-btns {
    display: inline-block; }
  .button-builder .button-generator-bx {
    position: relative;
    width: 100%; }
  .page-builder .btn-group {
    display: inline-block;
    margin-bottom: 10px; }
  .page-builder .btn-grid {
    margin-bottom: 15px;
    margin-right: 0px;
    border-right: none;
    display: inline-block; }
  .page-builder .ge-canvas.ge-layout-desktop {
    margin-top: 50px; }
  .ecommerce-widget .total-num {
    font-size: 23px;
    letter-spacing: 0px; }
  .support-ticket-font .total-num {
    font-size: 22px; }
  .custom-card {
    padding: 15px; }
    .custom-card .card-social li a {
      padding: 12px; }
  .map-chart .chart-container {
    height: 300px; }
  .speed-chart .chart-container {
    height: 250px;
    margin-top: -40px;
    margin-bottom: 100px; }
  .candidcahrt {
    height: 360px; }
  .status-details h3 {
    font-size: 18px; }
  .chart-widget-top .total-value {
    font-size: 20px; }
  .status-widget .card-body {
    padding: 10px; }
  .user-status table thead tr th:nth-child(3) {
    min-width: auto; }
  .user-status table tbody tr td:first-child {
    min-width: auto; }
  .browser-widget .media {
    flex-wrap: wrap; }
    .browser-widget .media .media-body h3 {
      font-size: 20px; }
    .browser-widget .media .media-body p {
      margin-bottom: 2px; }
    .browser-widget .media .media-body h4 {
      font-size: 20px; }
  .testimonial i {
    font-size: 46px; }
  .testimonial p {
    margin-top: 20px; }
  .cal-date-widget .datepicker {
    margin: 0 auto;
    width: unset; }
  .company-card {
    padding: 20px; }
  .static-top-widget .media-body h3 {
    font-size: 30px; }
  .static-top-widget .media-body .icon-bg {
    right: -21px; }
  .widget-joins .media {
    padding: 20px; }
    .widget-joins .media .media-body h3 {
      font-size: 20px; }
  .redial-social-widget {
    width: 125px;
    height: 125px; }
    .redial-social-widget i {
      width: 100px;
      height: 100px;
      font-size: 35px; }
  .chat-body {
    padding: 10px; }
  .chat-box .chat-history .call-content .btn {
    font-size: 15px; }
  .chat-box .chat-history .total-time h2 {
    font-size: 30px; }
  .chat-box .chat-history .receiver-img {
    margin-top: 30px;
    margin-bottom: 30px; }
  .chat-box .chat-history .call-icons {
    margin-top: 20px;
    margin-bottom: 20px; }
    .chat-box .chat-history .call-icons ul li {
      width: 50px;
      height: 50px;
      padding: 7px; }
  .chat-box .user-image {
    width: 30px;
    height: 30px;
    margin-top: 3px;
    margin-right: 3px; }
  .chat-box .about {
    margin-top: 0; }
  .chat-box .border-tab.nav-tabs .nav-item a {
    padding: 13px 4px !important;
    color: #aaaaaa !important;
    letter-spacing: 0;
    font-size: 12px !important;
    line-height: 6; }
  .chat-box .chat-menu .people-list ul.list {
    max-height: 376px;
    overflow-y: auto; }
  .chat-box .chat-menu .user-profile {
    margin-top: 0; }
    .chat-box .chat-menu .user-profile .image .avatar img {
      width: 100px;
      height: 100px; }
    .chat-box .chat-menu .user-profile .image .icon-wrapper {
      width: 30px;
      height: 30px;
      font-size: 13px; }
    .chat-box .chat-menu .user-profile .user-content p {
      font-size: 14px;
      margin-bottom: 10px; }
    .chat-box .chat-menu .user-profile .user-content h5 {
      margin: 20px 0; }
    .chat-box .chat-menu .user-profile .user-content hr {
      margin: 20px 0; }
    .chat-box .chat-menu .user-profile .follow .follow-num {
      font-size: 20px; }
  .chat-left-aside .people-list {
    height: 490px; }
    .chat-left-aside .people-list ul {
      max-height: 434px;
      overflow-y: auto; }
  .status-circle {
    top: 25px;
    left: 22px; }
  .mega-menu .onhover-show-div {
    left: 164px; }
  .img-cropper .docs-toggles {
    margin-top: 8px; }
  .bootstrap-touchspin .dropdown-basic .dropdown .dropdown-content {
    left: -21px; }
  #advance-5_wrapper .dataTables_paginate {
    margin-bottom: 8px; }
  #batchDelete .jsgrid-grid-header .jsgrid-table .jsgrid-header-row .jsgrid-header-cell:first-child {
    width: 67px !important; }
  #batchDelete .jsgrid-grid-body .jsgrid-table .jsgrid-row .jsgrid-cell:first-child {
    width: 67px !important; }
  .reset-password-box .theme-form .btn {
    padding: 6px 22px; } }

/* ========= responsive scss for 1200 screen ========= */
@media only screen and (max-width: 1199px) {
  .flot-chart-container #toggling-series-flot {
    width: 82% !important; }
  .user-card .online-user:before {
    left: -25px; }
  .pixelstrap ul ul {
    background: rgba(68, 102, 242, 0.07); }
  .mega-menu-header .vertical-mobile-sidebar {
    position: absolute;
    right: 310px;
    top: 15px; }
  .product-wrapper-grid.list-view .product-box .product-img {
    width: 32%; }
  .card .card-body {
    padding: 25px; }
  .mt50 {
    margin-top: 30px; }
  .landing-center-responsive {
    padding: 150px 0; }
  .landing-main .support-res {
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 20px; }
  .bg-gallery {
    height: 100vh; }
  .landing-center .landing_first_section_img .img-set3 {
    margin: 0 auto;
    width: 600px; }
  .alert-theme {
    min-width: 420px; }
  .photos ul li {
    width: auto; }
    .photos ul li:nth-child(3n) {
      margin-right: 15px; }
  .user-profile .hovercard .user-image .share-icons {
    position: absolute;
    right: 45px;
    top: -22px; }
    .user-profile .hovercard .user-image .share-icons li {
      color: #fff;
      font-size: 25px; }
      .user-profile .hovercard .user-image .share-icons li .social-icon {
        width: 40px;
        height: 40px; }
        .user-profile .hovercard .user-image .share-icons li .social-icon i {
          font-size: 18px; }
  .timeline-small .media .timeline-round.timeline-line-1:after {
    height: 50px;
    bottom: -60px; }
  .timeline-small .media .timeline-round.small-line:after {
    height: 28px;
    bottom: -38px; }
  .timeline-small .media .timeline-round.medium-line:after {
    height: 48px;
    bottom: -60px; }
  .timeline-small .media .media-body h6 {
    font-weight: 600;
    margin-bottom: 30px; }
    .timeline-small .media .media-body h6 span {
      font-weight: normal;
      color: #999; }
  .timeline-small .media .media-body p {
    width: 80%; }
  .fixed {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    padding: 4px 0;
    animation: slide-down 0.7s;
    opacity: 1;
    animation-direction: normal;
    animation-duration: 0.7s;
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #2c91fb;
    z-index: 4; }
  .sticky-header header.sticky .navbar-expand-xl .navbar-brand {
    top: 6px; }
  .sticky-header header.sticky .navbar-expand-xl .navabr_btn-set {
    top: 15px; }
  .landing-main h4 {
    font-size: 20px; }
  .landing-main .navbar-expand-xl .navbar-brand {
    padding-left: 5px;
    z-index: 1;
    position: fixed;
    top: 14px; }
  .landing-main .navbar-expand-xl .navabr_btn-set {
    position: fixed;
    top: 24px;
    z-index: 1;
    right: 20px; }
  .landing-main .navbar-expand-xl li a .btn-md {
    margin: 28px 0; }
  .landing-main .navbar-expand-xl .navbar-collapse .navbar-nav .nav-item a {
    padding: 28px 60px;
    width: 16px;
    margin: 0 auto;
    text-align: center;
    display: flex;
    justify-content: center; }
  .landing-main .navbar-expand-xl .navbar-collapse .navbar-nav .nav-item .btn-res {
    width: auto;
    text-align: center;
    display: flex;
    justify-content: center;
    margin: 28px 0; }
  .landing-main .navbar_nav_modify {
    width: 100vw;
    left: 0;
    position: fixed;
    height: 100vh;
    background-color: #293240;
    top: 0;
    padding: 120px 0px; }
  .lg-mt-col {
    margin-top: 30px; }
  .xl-none {
    display: none; }
  .user-status table tbody tr td .d-inline-block {
    margin-top: 10px; }
  .lg-mt {
    margin-top: 30px; }
  .product-page-details {
    margin-top: 30px; }
  .debit-card {
    order: 1; }
  .typography .row div + div {
    margin-top: 30px; }
  .listing .card-body .row > div + div {
    margin-top: 30px; }
  .smooth-chart.flot-chart-container, .flot-chart-container {
    height: 250px; }
  .img-cropper #putData {
    margin-bottom: 10px; }
  .img-cropper .docs-toggles > .dropdown {
    margin-bottom: 0; }
  .error-wrapper .maintenance-icons li i {
    color: #f6f7fb;
    font-size: 40px; }
  .error-wrapper .maintenance-icons li:nth-child(2) i {
    font-size: 80px;
    margin-left: 90px; }
  .error-wrapper .maintenance-icons li:nth-child(3) i {
    font-size: 120px;
    margin-top: -75px;
    margin-right: 110px; }
  div.dataTables_wrapper div.dataTables_paginate ~ .dataTables_filter {
    display: block;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 0; }
  .jvector-map-height {
    height: 280px; }
  .blog-list .blog-details p {
    margin-bottom: 5px; }
  .custom-card .card-profile img {
    height: 210px; }
  .custom-card .card-profile .card-footer > div h3 {
    font-size: 18px; }
  .equal-height-lg {
    min-height: auto !important; }
  .mega-menu .onhover-show-div {
    height: 435px;
    overflow-x: scroll;
    left: 215px; }
  .mega-menu .mega-bg {
    display: none; }
  .mega-menu .lg-mt {
    margin-top: 25px; }
  .button-builder .button-generator-bx {
    position: relative;
    width: 100%; }
  .blog-single .comment-box ul .comment-social {
    margin-left: 0; }
  .error-wrapper .maintenance-heading {
    margin-top: 30px; }
    .error-wrapper .maintenance-heading .headline {
      font-size: 50px;
      margin-top: -15%;
      margin-bottom: 0px; }
    .error-wrapper .maintenance-heading .cloud-second {
      margin-top: -25px; }
  .contact-form .theme-form {
    margin-top: 10px; }
  .user-status table tbody tr td:first-child {
    min-width: 278px; }
  .testimonial p {
    margin-top: 17px; }
  .contact-form {
    padding: 10px; }
    .contact-form .theme-form .form-icon {
      margin-top: -57px; }
  .calender-widget .cal-date {
    width: 90px;
    height: 90px; }
    .calender-widget .cal-date h5 {
      font-size: 16px;
      padding: 18px; }
  .calender-widget .cal-desc {
    padding: 14px; }
    .calender-widget .cal-desc p {
      max-height: 114px;
      overflow-y: auto; }
  .browser-widget {
    padding: 0px; }
    .browser-widget .media .media-body h4 {
      font-size: 17px; }
    .browser-widget .media .media-img {
      width: 100%;
      text-align: center;
      margin-bottom: 20px; }
  .crm-overall .overall-chart {
    height: 200px; }
  .chat-box .chat-menu .people-list ul.list {
    max-height: 354px;
    overflow-y: auto; }
  .chat-box .btn-lg {
    font-size: 12px; }
  .chat-box .people-list ul {
    height: auto; }
  .chat-box .chat-right-aside .chat .chat-header {
    height: auto; }
    .chat-box .chat-right-aside .chat .chat-header .chat-menu-icons li a i {
      font-size: 19px; }
  .chat-box .chat-right-aside .chat .chat-message {
    margin: 0; }
  .chat-box .chat-history .total-time h2 {
    font-size: 25px; }
  .chat-box .chat-history .row .col-sm-7 {
    padding-right: 0; }
  .chat-box .border-tab.nav-tabs {
    width: 100%; }
    .chat-box .border-tab.nav-tabs .nav-item {
      margin-right: 5px; }
  .chat-box .chat-left-aside .people-list {
    height: 493px; }
  .chat-menu-icons {
    display: block;
    clear: both;
    width: 100%;
    text-align: right; }
  .chat-menu {
    right: 0;
    border-top: 1px solid #eff0f1;
    opacity: 0;
    transform: translateY(-30px);
    visibility: hidden;
    top: 120px;
    position: absolute !important;
    z-index: 9;
    background-color: #fff;
    transition: all linear 0.3s; }
    .chat-menu.show {
      opacity: 1;
      visibility: visible;
      transform: translateY(0px);
      transition: all linear 0.3s;
      padding-bottom: 25px; }
  .chat-history {
    height: 447px; }
  .chat-box .chat-history .call-content > div {
    padding-top: 70px; }
  .chat-box .toogle-bar {
    display: inline-block;
    margin-right: 0 !important; }
  .chat-right-aside {
    flex: 0 0 100%;
    max-width: calc(100% - 15px);
    overflow: hidden; }
  .call-chat-body .chat-box {
    overflow: auto;
    height: auto; }
  .email-wrap .email-right-aside .email-body {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px; }
    .email-wrap .email-right-aside .email-body .b-r-light {
      border-right: none !important; }
  .email-wrap .email-right-aside .radius-left {
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important; }
  .email-wrap .row .col-xl-3 + .col-xl-3 {
    padding-right: 15px; }
  .email-wrap .row .col-xl-6 {
    padding-left: 15px; }
  .icon-lists div {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 209px; }
  .like-comment-lg-mb {
    margin-bottom: 12px; }
  .ribbon-space-bottom {
    top: 9px; }
  .bootstrap-touchspin .touchspin-vertical-tab .input-group {
    width: 98.5% !important; }
  #batchDelete .jsgrid-grid-header .jsgrid-table .jsgrid-header-row .jsgrid-header-cell:first-child {
    width: 95px !important; }
  #batchDelete .jsgrid-grid-body .jsgrid-table .jsgrid-row .jsgrid-cell:first-child {
    width: 95px !important; }
  .profile-img-style #aniimated-thumbnials-3 {
    text-align: center; }
  .vertical-menu-mt {
    margin-top: 80px !important; }
    .vertical-menu-mt .xl-none {
      display: block; }
  .sm {
    position: fixed;
    background-color: #fff;
    width: 300px;
    border-radius: 0;
    border: 1px solid #f6f7fb;
    top: 80px;
    height: 100vh;
    left: -300px;
    z-index: 99;
    transition: all 0.3s ease;
    overflow: scroll; }
  .calendar-wrap .fc-day-grid-event {
    width: 87%; } }

@media (max-width: 1199px) and (min-width: 992px) {
  .page-wrapper .page-body-wrapper .page-sidebar.open ~ .page-body .timeline-small .media .timeline-round.timeline-line-1:after {
    bottom: -44px;
    height: 40px; }
  .page-wrapper .page-body-wrapper .page-sidebar.open ~ .page-body .timeline-small .media .timeline-round.medium-line:after {
    height: 30px;
    bottom: -35px; } }

@media only screen and (max-width: 1169) {
  #cd-timeline::before {
    left: -17px; } }

@media screen and (max-width: 1120px) {
  .bg-gallery {
    height: 94vh; } }

@media screen and (max-width: 1024px) {
  .bg-gallery {
    height: 90vh; } }

/* ========= responsive scss for 992 screen ========= */
@media only screen and (max-width: 991px) {
  .vertical-menu-mt {
    margin-top: 60px !important; }
  .mega-menu-header .vertical-mobile-sidebar {
    right: 90px;
    top: 18px; }
  .product-box .modal .modal-header .product-box .product-details {
    padding-left: 15px;
    padding-right: 15px; }
  .user-card .online-user:before {
    left: -21px; }
  .search-form .form-group:before {
    top: 31px;
    left: 74px; }
  .search-form .form-group:after {
    top: 33px;
    left: 45px; }
  .product-sidebar .card {
    margin-bottom: 15px; }
    .product-sidebar .card .card-header {
      padding: 16px 30px !important; }
  .switch-sm .switch svg {
    height: 20px; }
  .bookmark ul li a svg {
    height: 20px; }
  .card .card-header h5 {
    font-size: 17px; }
  .customizer-contain {
    top: 60px; }
  .page-header .col {
    flex-basis: auto; }
  .page-header .bookmark.pull-right {
    float: left;
    margin-top: 8px; }
  .page-header .bookmark ul li .search-form .form-control-search {
    left: -30px;
    right: unset; }
  .grid-options ul li:nth-child(3) {
    display: none; }
  .product-sidebar {
    top: 147px; }
  .feature-products form .form-group i {
    right: 20px; }
  .bg-gallery {
    height: 85vh; }
  .brush-setting ul {
    top: 44%; }
  .landing-center .footer-content h1 {
    font-size: 18px;
    line-height: 1.5; }
  .footer-pattern-root:before {
    position: absolute;
    content: '';
    width: 10px;
    height: 4px;
    background-color: white;
    border-radius: 5px;
    left: calc(52% - -6px); }
  .footer-pattern-root:after {
    position: absolute;
    content: '';
    width: 10px;
    height: 4px;
    border-radius: 5px;
    background-color: white;
    right: calc(52% - -6px); }
  .landing_gallery_section_img .img-gallery-set2 {
    bottom: 80px; }
  .landing_gallery_section_img .img-gallery-set3 {
    bottom: 80px; }
  .landing_gallery_section_img .img-gallery-set4 {
    bottom: 80px; }
  .landing_gallery_section_img:before {
    height: 500px; }
  .landing-center .landing_first_section_img {
    width: 68% !important; }
    .landing-center .landing_first_section_img .img-set3 {
      width: 480px; }
    .landing-center .landing_first_section_img .img-set1 {
      left: -170px !important;
      top: 95px !important; }
      .landing-center .landing_first_section_img .img-set1 img {
        width: 90%; }
    .landing-center .landing_first_section_img .img-set2 {
      right: -170px !important;
      top: 90px !important; }
  .landing-center .img-wave2-set {
    bottom: 0;
    height: 11%; }
  .landing-center .img-wave1-set {
    bottom: 20px;
    height: 18%; }
  .landing-center .title-content {
    width: 100%; }
  .bg-sky-blue {
    height: 910px; }
  .page-wrapper .section-space {
    padding: 60px 0; }
  .landing_gallery_section_img {
    margin-bottom: -60px; }
  .creative-demo-section .gallery_content p {
    width: 70%; }
  .creative-demo-section .creative-demo-content p {
    width: 70%; }
  .svg_img2 img {
    width: 67%; }
  .svg_img2:before {
    left: calc(81% + 6px);
    width: 18px;
    height: 18px;
    border: 2px solid #fd99ac;
    border-radius: 50%; }
  .mobile-clock-widget #date {
    margin-top: 20px; }
  .page-wrapper .page-body-wrapper .sidebar {
    height: calc(100vh - 60px); }
  .page-wrapper .page-body-wrapper footer {
    margin-left: 0; }
  .page-wrapper .page-body-wrapper .page-header .row .page-header-left {
    display: inherit; }
  .page-wrapper .page-body-wrapper .page-header .breadcrumb {
    margin-left: 0; }
  .m-r-30 {
    margin-right: 20px; }
  .card.full-card {
    top: 60px;
    width: calc(100vw - 0px); }
  .document-content .logo-wrapper {
    display: none; }
  .document-content .page-sidebar {
    top: 80px !important; }
  .sm {
    top: 60px; }
  .page-wrapper.compact-wrapper .page-main-header {
    margin-left: 0;
    width: calc(100% - 0px); }
  .page-wrapper.compact-wrapper .page-body-wrapper.sidebar-icon .footer {
    margin-left: 0px;
    width: calc(100% - 0px); }
  .page-wrapper.compact-page .page-main-header {
    margin-left: 0;
    width: calc(100% - 0px); }
  .page-wrapper.compact-page .footer {
    margin-left: 0; }
  .page-wrapper .page-body-wrapper.sidebar-icon .page-sidebar ~ .page-body {
    margin-left: 0; }
  .page-wrapper .page-body-wrapper.sidebar-icon.sidebar-close .page-sidebar {
    left: -150px; }
  .page-wrapper .page-body-wrapper.sidebar-hover .page-sidebar.open {
    transform: translate(-266px); }
  .page-body-wrapper.sidebar-hover .page-sidebar.open {
    margin-left: 6px !important; }
  .page-body-wrapper.sidebar-hover .page-body {
    margin-left: 0 !important; }
    .page-body-wrapper.sidebar-hover .page-body #customer-review .owl-stage-outer {
      width: 100% !important; }
    .page-body-wrapper.sidebar-hover .page-body .calender-widget .cal-desc p {
      max-height: 101px; }
  .gallery {
    margin-bottom: -20px; }
  .my-gallery {
    margin-bottom: -20px; }
    .my-gallery figure {
      margin-bottom: 20px; }
  .photoswipe-pb-responsive {
    padding-bottom: 25px !important; }
  .page-wrapper .page-main-header .main-header-right .nav-right .notification {
    top: 10px; }
  .page-wrapper .page-main-header .main-header-left .logo-wrapper img {
    height: 35px;
    margin-top: 0;
    margin-right: 15px; }
  .crm-activity > li + li {
    margin-top: 10px;
    padding-top: 10px; }
  .add-post .dropzone {
    margin-bottom: 20px; }
  .customers.avatar-group {
    margin-right: 20px; }
  .main .langChoice {
    right: 20px;
    top: 11px; }
  .socialprofile .social-btngroup {
    margin: 20px 0; }
  .socialprofile .social-group {
    margin-top: 20px; }
  .social-status form .form-control {
    margin-bottom: 20px; }
  .social-status .media {
    margin-bottom: 20px; }
  .timeline-content p, .timeline-content .comments-box {
    margin-top: 20px; }
  .social-chat {
    margin-top: 20px; }
    .social-chat .media-body {
      padding: 20px; }
    .social-chat .your-msg, .social-chat .other-msg {
      margin-bottom: 20px; }
  .social-network span {
    margin-bottom: 20px; }
  .details-about + .details-about {
    margin-top: 20px; }
  .activity-log .my-activity + .my-activity {
    margin-top: 20px; }
  .new-users-social {
    margin-bottom: 20px; }
  .timeline-small {
    margin-top: 14px; }
    .timeline-small .media {
      margin-bottom: 20px; }
      .timeline-small .media .media-body h6 {
        margin-bottom: 20px; }
      .timeline-small .media .timeline-round {
        width: 45px;
        height: 45px;
        top: -14px; }
        .timeline-small .media .timeline-round.timeline-line-1:after {
          bottom: -46px !important;
          height: 35px; }
        .timeline-small .media .timeline-round.small-line:after {
          bottom: -26px;
          height: 14px; }
        .timeline-small .media .timeline-round.medium-line:after {
          bottom: -41px;
          height: 28px; }
        .timeline-small .media .timeline-round svg {
          top: 14px; }
  .landing-main .advance-widget .support-section {
    margin-top: 18px; }
  .landing-main .advance-widget .widgets-section {
    height: 500px; }
  .landing-main .section-space p {
    font-size: 15px; }
  .landing-main .landing-header h2 {
    margin-bottom: 20px; }
  .landing-main .section-space {
    padding: 60px 0; }
  .landing-main .footer-bg h2 {
    font-size: 28px; }
  .landing-main .footer-bg .star-rate i {
    font-size: 40px; }
  .landing-main .support-section p {
    font-size: 13px; }
  .helper-classes {
    padding: 20px; }
  .floated-customizer-btn, .floated-customizer-panel {
    display: none; }
  .page-builder .ge-canvas.ge-editing .row {
    padding: 20px; }
  .weather-widget-two .bottom-whetherinfo .whether-content {
    top: 25px; }
  .social-widget-card .b-b-light {
    padding: 20px;
    margin-bottom: 20px; }
  .ecommerce-widget .progress-showcase {
    margin-top: 20px; }
  .crm-overall {
    margin: 0 -20px -20px; }
  .product-page-main {
    padding: 20px; }
  .tabbed-card ul {
    padding: 18px 15px; }
  .tabbed-card ul {
    top: -5px; }
    .tabbed-card ul.border-tab.nav-tabs {
      padding: 0; }
      .tabbed-card ul.border-tab.nav-tabs .nav-item .nav-link {
        padding: 18px 15px; }
  .tilt-showcase .tilt-image {
    text-align: center; }
  .tilt-showcase .mt-4 {
    margin-top: 20px !important; }
  .todo .todo-list-wrapper .mark-all-tasks {
    top: 20px;
    right: 20px; }
  .custom-card {
    padding-bottom: 0; }
    .custom-card .card-footer {
      padding: 0 15px 15px 15px !important; }
    .custom-card .card-header {
      border: none; }
    .custom-card .dashboard-card {
      padding: 0; }
    .custom-card .card-profile img {
      height: 155px; }
  .display-1 {
    font-size: 4rem; }
  .display-2 {
    font-size: 3.5rem; }
  .display-3 {
    font-size: 2.5rem; }
  .display-4 {
    font-size: 1.5rem; }
  .typography .h1 {
    font-size: 34px; }
  .typography .h2 {
    font-size: 30px; }
  .typography .h3 {
    font-size: 26px; }
  .typography .h4 {
    font-size: 22px; }
  .typography .h5 {
    font-size: 18px; }
  .typography .h6 {
    font-size: 15px; }
  h1 {
    font-size: 34px; }
  h2 {
    font-size: 28px; }
  h3 {
    font-size: 26px; }
  h4 {
    font-size: 22px; }
  h5 {
    font-size: 18px; }
  h6 {
    font-size: 15px; }
  .smooth-chart.flot-chart-container, .flot-chart-container {
    height: 300px; }
  .card-absolute .card-header {
    padding: 10px 15px !important; }
  .comingsoon .comingsoon-inner .countdown .time {
    width: 80px;
    height: 80px;
    font-size: 26px; }
  footer {
    bottom: -20px; }
  .calendar-wrap .basic-calendar .external-events .fc-event + .fc-event {
    margin-top: 10px; }
  .owl-theme .owl-nav.disabled + .owl-dots {
    margin-bottom: -10px;
    margin-top: 15px; }
  .card .card-header .card-header-right {
    top: 13px;
    right: 10px; }
  .card .server-header .card-header-right {
    top: 14px; }
  .page-wrapper .page-main-header {
    height: 60px;
    margin-left: 0;
    width: 100%; }
    .page-wrapper .page-main-header .main-header-right .nav-right {
      position: unset;
      padding: 0; }
      .page-wrapper .page-main-header .main-header-right .nav-right > .mobile-toggle {
        cursor: pointer;
        display: flex;
        align-items: center; }
        .page-wrapper .page-main-header .main-header-right .nav-right > .mobile-toggle svg circle {
          color: #4466f2; }
      .page-wrapper .page-main-header .main-header-right .nav-right > ul {
        top: 60px;
        position: absolute;
        z-index: -1;
        background-color: #fff;
        transition: all linear 0.3s;
        box-shadow: 0 2px 2px 2px #efefef;
        width: 100%;
        left: 0;
        padding: 0 40px;
        transform: translateY(-35px) scaleY(0);
        opacity: 0;
        visibility: hidden; }
        .page-wrapper .page-main-header .main-header-right .nav-right > ul.open {
          z-index: 1;
          opacity: 1;
          transform: translateY(0px) scaleY(1);
          visibility: visible; }
        .page-wrapper .page-main-header .main-header-right .nav-right > ul > li {
          margin: 10px 0; }
          .page-wrapper .page-main-header .main-header-right .nav-right > ul > li .onhover-show-div {
            top: 55px; }
  .page-wrapper .page-body-wrapper .page-sidebar {
    top: 60px;
    height: calc(100vh - 60px); }
    .page-wrapper .page-body-wrapper .page-sidebar ~ .page-body {
      margin-left: 0px; }
  .page-wrapper .page-body-wrapper .page-sidebar-open {
    width: 250px; }
  .page-wrapper .page-body-wrapper .page-header {
    padding-top: 25px;
    padding-bottom: 25px; }
    .page-wrapper .page-body-wrapper .page-header .row h3 {
      font-size: 20px; }
    .page-wrapper .page-body-wrapper .page-header .breadcrumb {
      float: left;
      padding-left: 0;
      margin-top: 7px; }
  .page-wrapper .page-body-wrapper .page-body {
    margin-top: 60px;
    padding: 0 10px; }
  .page-wrapper .page-body-wrapper .default-according .card .card-header, .page-wrapper .page-body-wrapper .default-according .card .card-body {
    padding: 0.75rem 1.25rem; }
  .page-wrapper .page-body-wrapper .card .card-header, .page-wrapper .page-body-wrapper .card .card-body, .page-wrapper .page-body-wrapper .card .card-footer {
    padding: 20px; }
    .page-wrapper .page-body-wrapper .card .card-header .tab-content .m-t-30, .page-wrapper .page-body-wrapper .card .card-body .tab-content .m-t-30, .page-wrapper .page-body-wrapper .card .card-footer .tab-content .m-t-30 {
      margin-top: 20px !important; }
    .page-wrapper .page-body-wrapper .card .card-header .tab-content .m-b-30, .page-wrapper .page-body-wrapper .card .card-body .tab-content .m-b-30, .page-wrapper .page-body-wrapper .card .card-footer .tab-content .m-b-30 {
      margin-bottom: 20px !important; }
  .page-wrapper .page-body-wrapper .user-profile .profile-img-style {
    padding: 20px; }
    .page-wrapper .page-body-wrapper .user-profile .profile-img-style .img-container {
      margin-top: 20px; }
  .page-wrapper .page-body-wrapper .user-profile hr {
    margin: 20px 0; }
  .page-wrapper .page-body-wrapper .user-profile .like-comment {
    margin-top: 20px; }
  .page-wrapper .page-body-wrapper .right-sidebar {
    top: 130px; }
  .page-builder .ge-addRowGroup {
    width: 100%; }
  .user-profile .hovercard .info .user-designation {
    margin-bottom: 20px; }
  .blog-single .comment-box .comment-social li {
    padding-left: 15px; }
  .error-wrapper {
    padding: 35px 0; }
    .error-wrapper .sub-content {
      font-size: 14px;
      line-height: 25px;
      margin-top: 0px; }
    .error-wrapper .error-heading {
      margin-top: 30px; }
      .error-wrapper .error-heading .headline {
        font-size: 180px;
        margin-top: 0; }
    .error-wrapper .maintenance-heading {
      margin-top: 30px; }
  .search-page .info-block {
    flex-wrap: wrap; }
  .search-page .tab-content .pb-4 {
    padding-bottom: 20px !important; }
  .comingsoon .comingsoon-inner img {
    width: 150px; }
  .calender-widget .cal-desc {
    padding: 10px 20px 20px !important; }
    .calender-widget .cal-desc p {
      max-height: 100px; }
  .contact-form {
    padding-top: 10px !important; }
    .contact-form .theme-form {
      padding: 20px;
      margin-top: 27px; }
      .contact-form .theme-form .form-icon {
        margin-top: -47px; }
  .user-status.product-chart {
    max-height: 518px;
    overflow: auto; }
  .widget-joins .media .media-body i {
    float: none !important; }
  .xl-none {
    display: block; }
  .top-radius-blog {
    width: 100%; }
  .sm-100-w {
    width: 100%;
    height: 100%; }
  .page-wrapper .page-body-wrapper .footer-fix {
    margin-left: 0px;
    padding-right: 15px;
    width: calc(100% - 0px); }
  /*Form Wizard One start*/
  .form-wizard .btn-mb {
    margin-bottom: 20px; }
  /*Form Wizard One ends*/
  /*Form Wizard Two Start*/
  .stepwizard {
    margin-bottom: 20px; }
  /*Form Wizard Two Ends*/
  /*Form Wizard Three Start*/
  .f1 .f1-steps {
    margin-top: 20px;
    margin-bottom: 20px; }
  /*Form Wizard Three ends*/
  /*Form Wizard Four Start*/
  .wizard-4 ul.anchor {
    padding-right: 20px; }
  /*Form Wizard Four ends*/
  .border-tab.nav-tabs {
    margin-bottom: 20px; }
  .tab-content ~ .nav-tabs.border-tab {
    margin-top: 20px; }
  .dataTables_wrapper .dataTables_length {
    margin-bottom: 20px; }
  .dataTables_wrapper table.dataTable {
    margin-bottom: 20px !important; }
  .search-page .info-block + .info-block {
    padding-top: 20px;
    margin-top: 20px; }
  .search-page #video-links .embed-responsive
+ .embed-responsive {
    margin-top: 20px; }
  .lg-mt {
    margin-top: 20px; }
  .gallery-with-description {
    margin-bottom: -20px; }
    .gallery-with-description a > div {
      margin-bottom: 20px; }
  .clipboaard-container p {
    margin-bottom: 8px; }
  .m-b-30 {
    margin-bottom: 20px !important; }
  .email-wrap .email-left-aside .email-app-sidebar ul li hr {
    margin: 13px 0; }
  .nav-md-mt {
    margin-top: 20px; }
  .navs-icon {
    padding: 20px; }
    .navs-icon .main-section {
      padding-top: 20px; }
    .navs-icon .separator {
      margin: 20px 0; }
  .nav-list {
    padding: 20px; }
  .navs-dropdown button {
    margin-top: 20px; }
  .navs-dropdown .onhover-show-div {
    top: 68px; }
  .animated-modal .form-group {
    width: 100%;
    display: block; }
  .animated-modal .mr-4 {
    margin-right: 0 !important; }
  .animated-modal .animated-modal-md-mb {
    margin-bottom: 20px !important; }
  .steps-md-mt {
    margin-top: 20px; }
  .btn-group-wrapper .m-b-30:last-child {
    margin-bottom: 0 !important; }
  #advance-5_wrapper .dataTables_paginate {
    margin-bottom: 20px; }
  .click2edit ~ .note-editor.note-frame {
    margin-bottom: 20px; }
  .chat-box .chat-left-aside .people-list {
    height: 483px; } }

@media only screen and (max-width: 900px) {
  .bg-gallery {
    height: 80vh; } }

@media only screen and (max-width: 800px) {
  .bg-gallery {
    height: 75vh;
    min-height: 560px; } }

/* ========= responsive scss for 768 screen ========= */
@media only screen and (min-width: 768px) {
  .navbar-expand-md .navbar-collapse {
    justify-content: flex-end; } }

@media only screen and (max-width: 767px) {
  .call-chat-sidebar {
    width: 100%;
    max-width: unset; }
  .form-inline .form-group {
    width: 100%; }
    .form-inline .form-group input {
      width: 100%; }
    .form-inline .form-group + .form-group {
      margin-top: 10px; }
  .tabbed-card .border-tab.nav-tabs {
    position: relative;
    top: unset !important;
    right: unset;
    display: block;
    width: 100%;
    float: left; }
    .tabbed-card .border-tab.nav-tabs .nav-item {
      float: left !important; }
  .product-filter .product-box {
    flex-wrap: nowrap; }
    .product-filter .product-box .product-img {
      width: 100px; }
    .product-filter .product-box .product-details {
      padding: 0 !important; }
  .product-wrapper.sidebaron .product-sidebar .filter-section .card .left-filter {
    top: unset; }
  .feature-products span.f-w-600 {
    padding-top: 8px;
    display: inline-block; }
  .page-wrapper .section-space {
    padding: 30px 0; }
  .landing-center-responsive {
    padding: 120px 0; }
  .landing-main .navbar_nav_modify {
    padding: 60px 0;
    display: flex;
    align-items: center;
    justify-content: center; }
  .landing-main .support-res {
    padding: 0 0 10px; }
  .landing-main .navbar-expand-xl .navbar-collapse .navbar-nav .nav-item a {
    padding: 12px 0; }
  .container-modify .img-effect {
    max-width: 320px;
    margin: 0 auto; }
  .container-modify .cretive_img_content p {
    line-height: 1.4; }
  .creative-demo-section .cretive_img_content {
    margin-top: 20px;
    margin-bottom: 30px; }
  .creative-demo-content .couting:before {
    width: 25px;
    height: 25px;
    top: -50px; }
  .creative-demo-content h2:before {
    width: 50px;
    height: 50px;
    top: -10px;
    left: -50px; }
  .creative-demo-content h2:after {
    width: 30px;
    height: 30px;
    top: -55px;
    left: -30px; }
  .feature-products .products-total {
    margin-bottom: 10px; }
  .feature-products .text-right {
    text-align: left !important; }
  .feature-products .select2-drpdwn-product {
    float: left;
    margin-right: 10px; }
  .feature-products .filter-toggle {
    padding: 8px 15px;
    background-color: #fff;
    margin-left: 10px;
    border-radius: 5px; }
  .product-wrapper .product-grid .feature-products {
    margin-left: 0; }
  .product-wrapper.sidebaron .product-grid .product-wrapper-grid {
    margin-left: 0; }
  .product-wrapper .product-sidebar .filter-section .card .left-filter {
    z-index: 1;
    opacity: 1;
    visibility: visible;
    height: 100%;
    transition: 0.3s; }
  .d-none-productlist {
    display: block;
    margin-right: 10px; }
  .product-sidebar {
    transform: translateX(-300px) scaleX(0);
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
    height: 0;
    position: absolute;
    z-index: 9;
    width: 300px; }
    .product-sidebar.open {
      transform: translateX(0px) scaleX(1);
      box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
      visibility: visible;
      opacity: 1;
      height: auto;
      top: -45px; }
      .product-sidebar.open:before, .product-sidebar.open:after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 7px solid #f8f8f8;
        right: 120px;
        top: -7px; }
      .product-sidebar.open:after {
        border-bottom: 7px solid #fff; }
    .product-sidebar .filter-section .card-header {
      display: none; }
    .product-sidebar .filter-section .card {
      margin-top: 0; }
  .grid-options ul li {
    display: none; }
  .product-sidebar {
    top: 230px; }
  .product-grid .feature-products .pull-right {
    float: none;
    margin-top: 15px; }
  .product-wrapper-grid.list-view .product-box .product-img {
    width: 42%; }
  .page-wrapper .page-body-wrapper .page-header .row .col {
    flex-basis: unset; }
    .page-wrapper .page-body-wrapper .page-header .row .col .pull-right {
      float: none;
      margin-top: 10px; }
  .landing_gallery_section_img:before {
    height: 400px; }
  .brush-setting ul {
    top: 50%; }
  .and-many-more {
    font-size: 65px; }
  .and-more {
    top: 40%; }
  .footer-pattern-root:before {
    position: absolute;
    content: '';
    width: 10px;
    height: 4px;
    background-color: white;
    border-radius: 5px;
    left: calc(53% - -4px); }
  .footer-pattern-root:after {
    position: absolute;
    content: '';
    width: 10px;
    height: 4px;
    border-radius: 5px;
    background-color: white;
    right: calc(53% - -4px); }
  .bg-gallery {
    min-height: 530px; }
    .bg-gallery .round-tringle-square-animation {
      display: none; }
  .landing-center .title-content {
    width: 100%; }
  .landing-center .img-wave1-set {
    height: 15%; }
  .footer-bg .cross2, .footer-bg .square1, .footer-bg .square2, .footer-bg .square, .footer-bg .bg_circle4, .footer-bg .bg_circle3, .footer-bg .bg_circle2, .footer-bg .bg_circle1, .footer-bg .triangle_set {
    display: none; }
  .landing-center .landing_first_section_img {
    width: 70% !important; }
    .landing-center .landing_first_section_img .img-set3 img {
      width: 100%; }
    .landing-center .landing_first_section_img .img-set3 {
      margin: 0 auto;
      width: 360px; }
    .landing-center .landing_first_section_img .img-set1 {
      left: -220px !important;
      top: 95px !important; }
      .landing-center .landing_first_section_img .img-set1 img {
        width: 56%; }
    .landing-center .landing_first_section_img .img-set2 {
      right: -220px !important;
      top: 90px !important; }
      .landing-center .landing_first_section_img .img-set2 img {
        width: 56%;
        height: 85%; }
  .bg-sky-blue {
    height: 800px;
    min-height: auto; }
  .landing-center .img-wave2-set {
    height: 10%; }
  .img-set2 img {
    width: 100%;
    height: 410px; }
  .creative-demo-section .gallery_content p {
    width: 100%; }
  .component_responsive :nth-child(25), .component_responsive :nth-child(26), .component_responsive :nth-child(27) {
    margin-bottom: 30px; }
  .creative-demo-section .creative-demo-content p {
    width: 100%; }
  .starter-kit-fix .page-body {
    margin-bottom: 78px; }
  .navbar-toggler {
    position: absolute;
    right: 0; }
  .document .page-main-header {
    background-color: #4466f2; }
    .document .page-main-header .document-right-nav .navbar .navbar-collapse .navbar-nav .nav-item .nav-link {
      color: #4466f2 !important; }
  .document .navbar-collapse {
    position: absolute;
    width: 100%;
    background-color: #fff;
    top: 60px;
    padding: 15px;
    left: 0;
    right: 0; }
  .blog-box .blog-details-main .blog-social li {
    padding: 0 12px; }
  .blog-box .blog-details .blog-social li:first-child {
    padding-right: 25px; }
  .blog-box .blog-details .blog-social li + li {
    padding-left: 25px; }
  .footer-copyright ~ div {
    text-align: center; }
    .footer-copyright ~ div p {
      float: none !important;
      margin-top: 5px; }
  .user-profile .hovercard .user-image .share-icons {
    right: 25px;
    top: -19px; }
    .user-profile .hovercard .user-image .share-icons li .social-icon {
      width: 35px;
      height: 35px; }
  .tabs-scoial.border-tab.nav-tabs {
    display: flex; }
  .timeline-small .media .media-body p {
    width: 100%; }
  .landing-main .exclusive-image {
    display: none; }
  .landing-main .landing-home .landing-body {
    height: auto;
    padding-top: 20px;
    padding-bottom: 40px; }
  .landing-main .exclusive-features .container > .row .inner-block {
    background-color: #f3f3f3; }
  .page-wrapper .page-body-wrapper.sidebar-hover .page-body {
    margin-left: 0 !important; }
    .page-wrapper .page-body-wrapper.sidebar-hover .page-body .calender-widget .cal-desc p {
      max-height: 100% !important; }
  .chat-box .chat-right-aside .chat .chat-message .text-box .btn {
    font-size: 0; }
    .chat-box .chat-right-aside .chat .chat-message .text-box .btn:before {
      position: absolute;
      content: "\e661";
      font-family: themify;
      font-size: 18px;
      top: 11px;
      left: 20px; }
  .chat-box .border-tab.nav-tabs {
    display: flex; }
  .chat-box .chat-menu .nav-tabs .nav-item a {
    height: 50px; }
  .clockpicker-align-top {
    left: 55px !important;
    top: 470px !important; }
    .clockpicker-align-top .arrow {
      display: none; }
  .form-builder-column .form-body {
    min-height: 250px; }
  .form-builder-2-header {
    flex-wrap: wrap; }
    .form-builder-2-header > div {
      width: 100%;
      margin-top: 10px; }
      .form-builder-2-header > div nav {
        float: none; }
  .lg-mt-col {
    margin-top: 20px; }
  .form-builder .drag-box fieldset {
    padding: 20px;
    margin-top: 20px; }
  .form-builder #components {
    margin-top: 20px; }
  .form-builder .component label {
    padding-top: 0px; }
  .mega-menu .onhover-show-div {
    height: 415px;
    left: 0;
    top: 61px;
    padding: 20px; }
  .mega-menu .lg-mt {
    margin-top: 15px; }
  .landing-main .landing-home .landing-left h1, .landing-main .landing-home .landing-left h5, .landing-main .landing-home .landing-left p {
    color: #fff; }
  .landing-main .landing-home .landing-left .txt-danger,
  .landing-main .landing-home .landing-left .txt-primary,
  .landing-main .landing-home .landing-left .txt-info {
    color: #fff !important; }
  .landing-main .landing-home .landing-left .pr-5 {
    padding-right: 0 !important; }
  .landing-main .dashboard-image-pt-xs {
    padding-bottom: 30px; }
  .landing-main .advance-widget .widgets-section {
    height: 400px; }
  .landing-main .landing-header .line {
    margin-bottom: 40px; }
  .landing-main .section-space {
    padding: 40px 0; }
  .landing-main .large-number .large-number-block h4 span {
    font-size: 40px; }
  .page-builder .ge-canvas.ge-editing .ge-tools-drawer {
    margin: 10px 0 0 10px; }
    .page-builder .ge-canvas.ge-editing .ge-tools-drawer > a {
      padding: 0 5px; }
  .reader {
    margin-top: 15px;
    height: auto !important; }
  .range-slider.theme-form .form-group {
    margin-bottom: 0; }
  .note-editor .btn-group .btn {
    font-size: 11px; }
  .user-status tbody tr td {
    min-width: 284px; }
    .user-status tbody tr td + td {
      min-width: auto; }
      .user-status tbody tr td + td + td {
        min-width: 100px; }
  .text-md-right {
    text-align: right; }
  .star-ratings .stars {
    padding: 0; }
  .form-inline.d-inline-block {
    display: block !important;
    width: 100%; }
    .form-inline.d-inline-block .form-control {
      margin-bottom: 10px; }
  .reset-password-box {
    width: unset;
    margin: 0 auto; }
    .reset-password-box .card {
      padding: 20px; }
  .custom-card .card-profile img {
    height: 136px;
    bottom: 0; }
  .error-wrapper .maintenance-icons li i {
    color: #f6f7fb;
    font-size: 30px; }
  .error-wrapper .maintenance-icons li:nth-child(2) i {
    font-size: 60px;
    margin-top: -10px;
    margin-left: 70px; }
  .error-wrapper .maintenance-icons li:nth-child(3) i {
    font-size: 90px;
    margin-right: 80px; }
  .authentication-main .auth-innerright {
    min-height: auto;
    padding: 0; }
    .authentication-main .auth-innerright .social-media li {
      font-size: 14px; }
  .loader-box {
    justify-content: center; }
  .dataTables_wrapper .dataTables_length {
    margin-bottom: 20px; }
  .dataTables_wrapper .dataTables_filter {
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 20px; }
  div.dataTables_wrapper div.dataTables_info {
    padding-top: 0; }
  div.dataTables_wrapper div.dataTables_length label, div.dataTables_wrapper div.dataTables_filter label {
    float: none; }
  div.dataTables_wrapper div.dataTables_paginate {
    float: none;
    margin-top: 20px !important;
    margin-left: 0 !important; }
  .calendar-wrap .fc-basic-view .fc-day-number {
    padding: 0 0px !important; }
  .calendar-wrap .fc-events-container {
    margin-top: 0px; }
  .calendar-wrap .fc-events-container h6, .calendar-wrap .fc-events-container .checkbox {
    text-align: right; }
  .calendar-wrap .checkbox label {
    margin-top: 0; }
  .calendar-wrap .fc-toolbar .fc-left .fc-next-button .fc-icon-right-single-arrow:after {
    font-size: 12px !important; }
  .calendar-wrap .fc-toolbar .fc-left .fc-prev-button .fc-icon-left-single-arrow:after {
    font-size: 12px !important; }
  .calendar-wrap .fc-toolbar .fc-left .fc-today-button, .calendar-wrap .fc-toolbar .fc-right .fc-today-button {
    padding-top: 0px !important; }
  .calendar-wrap .fc {
    margin-top: 0px; }
    .calendar-wrap .fc button {
      font-size: 14px !important;
      padding: 0 5px !important; }
    .calendar-wrap .fc .fc-left {
      margin-bottom: 10px; }
    .calendar-wrap .fc .fc-toolbar > div {
      display: block !important;
      float: none !important; }
    .calendar-wrap .fc .fc-toolbar > * :first-child {
      font-size: 18px; }
    .calendar-wrap .fc .fc-toolbar > * > * {
      float: none !important; }
  .btn-group-wrapper {
    text-align: center; }
    .btn-group-wrapper .m-b-30 {
      margin-bottom: 15px !important; }
  .btn-group-showcase {
    text-align: center; }
    .btn-group-showcase .btn-group {
      margin-right: 0;
      margin-bottom: 15px; }
  .btn-group .btn {
    font-size: 14px;
    padding: 0.375rem 1.2rem; }
  .animate-widget .text-center {
    display: none; }
  .calender-widget .cal-desc p {
    max-height: 100%; }
  .user-status.product-chart {
    max-height: 100%; }
  .user-status table thead tr th:nth-child(3) {
    min-width: 124px; }
  .mega-menu .onhover-show-div {
    height: 350px; }
  .page-builder .ge-canvas .column {
    padding: 0 !important; }
  .page-builder .ge-content-type-ckeditor h3 {
    font-size: 20px; }
  .page-builder .ge-content-type-ckeditor p {
    font-size: 12px; }
  .page-builder .ge-row-icon {
    display: none !important; }
  /* font-awesom icon page */
  .icon-hover-bottom .icon-popup {
    display: block; }
    .icon-hover-bottom .icon-popup .icon-last {
      display: block;
      margin-top: 15px; }
    .icon-hover-bottom .icon-popup .form-group input {
      width: auto; }
  .icon-hover-bottom .form-group input {
    min-width: 270px; }
  .user-profile .hovercard .cardheader {
    height: 350px; }
  .user-profile .hovercard .info {
    padding: 35px; }
    .user-profile .hovercard .info .ttl-info {
      margin-bottom: 20px; }
    .user-profile .hovercard .info .ttl-sm-mb-0 {
      margin-bottom: 0; }
  .calendar-wrap .fc button {
    padding: 5px; }
  .calendar-wrap .fc-day-grid-event {
    margin: 0;
    padding: 5px;
    width: inherit; }
  .calendar-wrap .fc-toolbar h2 {
    font-size: 20px;
    line-height: 35px;
    font-weight: bold; }
  .calendar-wrap .basic-calendar .external-events {
    margin-top: 0px; }
  .blog-single .comment-box .media h6 {
    margin-bottom: 10px; }
  .blog-single .comment-box .media img {
    margin-right: 30px; }
  .blog-single .comment-box .comment-social {
    margin-bottom: 5px; }
    .blog-single .comment-box .comment-social li:first-child {
      padding-left: 0;
      padding-right: 15px; }
  .search-page #image-links .info-block.m-t-30 {
    margin-top: 0 !important; }
  .authentication-main .authentication-box {
    width: 100%; }
  .authentication-main .auth-innerright {
    display: inherit; }
  .map-chart .chart-container {
    height: 300px; }
  .candidcahrt {
    height: 250px; }
  .bar-chart-widget .earning-details i {
    right: 0; }
  .serial-chart .chart-container {
    height: 300px; }
  .bottom-content {
    padding: 10px; }
    .bottom-content p {
      font-size: 12px; }
  .stepwizard .stepwizard-row:before {
    top: 17px; }
  .icon-hover-bottom .icon-popup div .flag-icon {
    width: 30px;
    height: auto; }
  .icon-hover-bottom .icon-popup .form-group input {
    min-width: 40%; }
  .footer-links {
    text-align: center; }
  .footer-copyright {
    text-align: center !important; }
  .jvector-map-height {
    height: 150px; }
  .sm-left-text {
    text-align: left !important; }
  .email-wrap .email-right-aside .email-content .email-top .d-flex {
    padding-bottom: 0; }
  .email-wrap .email-right-aside .email-content .email-top .float-right {
    float: left !important; }
  .email-wrap .email-right-aside .email-content .email-wrapper .attachment {
    text-align: center; }
    .email-wrap .email-right-aside .email-content .email-wrapper .attachment ul li:last-child {
      padding-right: 0; }
    .email-wrap .email-right-aside .email-content .email-wrapper .attachment ul li img {
      width: 140px;
      height: 140px; }
  .alert-dismissible p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 294px; }
  .button-group-mb-sm {
    margin-bottom: 5px; }
  .card-block .table-responsive .table tbody tr td span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100px; }
  .card-block .table-responsive .progress-content tbody tr td span {
    text-overflow: ellipsis;
    white-space: normal;
    max-width: 50px; }
  .card-block .table-responsive .checkbox-td-width tbody tr td, .card-block .table-responsive .radio-first-col-width tbody tr td {
    min-width: 200px !important; }
  #batchDelete .jsgrid-grid-header .jsgrid-table .jsgrid-header-row .jsgrid-header-cell:first-child {
    width: 119px !important; }
  #batchDelete .jsgrid-grid-body .jsgrid-table .jsgrid-row .jsgrid-cell:first-child {
    width: 119px !important; }
  .jsgrid .jsgrid-pager-container {
    text-align: center; }
  .chat-box .chat-right-aside .chat .chat-msg-box {
    height: auto; }
  .chat-box .chat-menu .nav-tabs .nav-item {
    width: 32.33%; }
  .chat-box .chat-menu .people-list ul.list {
    max-height: 433px;
    margin-bottom: 15px; }
  .chat-box .chat-menu #info-profile, .chat-box .chat-menu #info-contact {
    margin-bottom: 15px; }
  .hovercard .info .follow .text-md-right {
    text-align: center; }
  .profile-img-style .like-comment-sm-mb {
    margin-bottom: 20px; }
  .reset-password-box .theme-form .btn {
    margin-top: 15px !important; }
  .lg-outer .lg-actions .lg-next, .lg-outer .lg-actions .lg-prev {
    top: 48%; } }

@media only screen and (max-width: 700px) {
  .bg-gallery {
    min-height: 470px; } }

@media only screen and (max-width: 650px) {
  .bg-gallery {
    height: 63vh; } }

@media only screen and (max-width: 600px) {
  .bg-gallery {
    height: 60vh; } }

@media only screen and (max-width: 540px) {
  .bg-gallery {
    height: 57vh; } }

/* ========= responsive scss for 575 screen ========= */
@media only screen and (min-width: 576px) {
  .product-wrapper-grid.list-view .col-sm-6 {
    flex: 0 0 100%;
    max-width: 100%; } }

@media only screen and (max-width: 575px) {
  .user-card .online-user:before {
    left: -16px; }
  .mega-menu-header .vertical-mobile-sidebar {
    right: 50px; }
  .bitcoin-message .input-group-append .btn {
    font-size: 0; }
    .bitcoin-message .input-group-append .btn:before {
      position: absolute;
      content: "\e661";
      font-family: themify;
      font-size: 18px;
      top: 11px;
      left: 20px; }
  .cal-date-widget .datepicker {
    padding: 15px; }
  ul.notification-dropdown.onhover-show-div {
    width: 284px;
    right: -112px; }
    ul.notification-dropdown.onhover-show-div li h6 small {
      display: none; }
    ul.notification-dropdown.onhover-show-div:before, ul.notification-dropdown.onhover-show-div:after {
      right: 135px !important; }
  .footer-bg .title img {
    height: 60px; }
  .ct-svg.flot-chart-container, .curves-2 .animate-curve, .github-chart {
    height: 200px; }
  .card-header.card-header-border .right-header {
    display: block;
    text-align: left; }
    .card-header.card-header-border .right-header .onhover-show-div {
      margin-left: 0; }
  .customizer-links {
    display: none; }
  .Typeahead-spinner {
    position: absolute;
    top: 114px;
    right: auto;
    color: #c5c5c5;
    left: 150px; }
  .Typeahead-menu {
    width: 250px;
    top: 320%; }
  .Typeahead {
    width: 15px;
    margin-top: -44px; }
    .Typeahead:focus {
      outline-color: white; }
    .Typeahead input:focus {
      outline: none; }
    .Typeahead .tt-hint {
      display: none; }
  .page-wrapper .page-main-header .main-header-right .nav-right > ul .search-form .form-control-plaintext {
    top: 100px !important; }
  .page-header .bookmark ul li .search-form .form-control-search input {
    width: 190px; }
  .brush-setting ul {
    display: none; }
  .landing-center .list-inline .vl:before {
    right: 80px;
    top: 9px; }
  .landing-center .list-inline .vl:after {
    left: 80px;
    top: 9px; }
  .landing-center .list-inline .list-inline-item {
    margin: 0 10px; }
  .landing-center .title .list-inline-item img {
    width: 80%; }
  .landing-center .footer-content h1 {
    font-size: 16px; }
  .product-wrapper-grid.list-view .product-box .ribbon {
    display: none; }
  .product-wrapper-grid.list-view .product-box .product-img {
    width: 48%; }
    .product-wrapper-grid.list-view .product-box .product-img .ribbon {
      display: none; }
  .feature-products form .form-group i {
    right: 15px; }
  .m-r-30 {
    margin-right: 15px; }
  .and-many-more {
    font-size: 52px; }
  .landing-center .title {
    margin-bottom: 20px; }
  .svg_img1 {
    display: none; }
  .landing-center .landing_first_section_img {
    width: 76% !important;
    margin: 0 auto; }
  .landing-header {
    height: 80px; }
  .bg-sky-blue {
    height: 690px;
    min-height: auto; }
  .landing-center .img-wave2-set {
    height: 8%; }
  .landing-center .img-wave1-set {
    display: none; }
  .landing_first_section_img {
    width: 70% !important; }
  .landing-center .landing_first_section_img .img-set3 {
    width: 325px;
    margin-top: 10px; }
  .landing-center .landing_first_section_img .img-set2 {
    display: none; }
  .landing-center .landing_first_section_img .img-set1 {
    display: none; }
  .page-wrapper .section-space {
    padding: 40px 0; }
  .landing-center .title-content h1 {
    font-size: 22px; }
  .landing-center .title-content p {
    font-size: 13px; }
  .components-section .component-col-set {
    max-width: 33.33333%; }
  .landing_gallery_section_img {
    margin-bottom: -40px; }
  .m-r-30 {
    margin-right: 15px; }
  .browser-widget .media .media-img {
    margin-bottom: 15px; }
  .mobile-clock-widget #date {
    margin-top: 15px; }
  .social-widget-card .b-b-light {
    padding: 15px;
    margin-bottom: 15px; }
  .weather-widget-two .bottom-whetherinfo .whether-content {
    top: 35px; }
  .cal-date-widget .cal-datepicker {
    margin-top: 15px; }
  .add-post form .m-checkbox-inline label {
    margin-right: 15px; }
  .add-post .dropzone {
    margin-bottom: 15px; }
  .main .langChoice {
    right: 15px;
    padding: 4px 7px; }
  .language-xs {
    margin-top: 15px; }
  .customers.avatar-group {
    margin-right: 15px; }
  .alert-theme {
    min-width: 300px; }
    .alert-theme i {
      margin-right: 10px !important;
      padding: 10px; }
    .alert-theme button {
      top: 2px !important; }
    .alert-theme .close {
      height: auto !important;
      top: 38px !important; }
    .alert-theme [data-notify='message'] {
      width: 290px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical; }
  .chart-widgets-small .chart-container {
    height: 220px !important; }
  .live-products, .turnover, .uses, .monthly {
    height: 220px !important; }
  .bar-chart-widget .earning-details i {
    right: -30px; }
  .widget-joins:before {
    display: none; }
  .widget-joins .ps-0 {
    padding-left: 15px !important; }
  .widget-joins .pe-0 {
    padding-right: 15px !important; }
  .widget-joins .border-after-xs:after {
    content: "";
    position: absolute;
    height: 1px;
    width: 94%;
    background-color: #eff0f1;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0 auto; }
  .clockpicker-align-top {
    left: 45px !important;
    top: 406px !important; }
  .gallery {
    margin-bottom: -15px; }
  .my-gallery {
    margin-bottom: -15px; }
    .my-gallery figure {
      margin-bottom: 15px; }
    .my-gallery.gallery-with-description figure:last-child {
      margin-bottom: 20px; }
  #aniimated-thumbnials figure:nth-child(12), #aniimated-thumbnials figure:nth-child(11), #aniimated-thumbnials figure:nth-child(10), #aniimated-thumbnials figure:nth-child(9) {
    margin-bottom: 15px; }
  .photoswipe-pb-responsive {
    padding-bottom: 30px !important; }
  .avatar-showcase .pepole-knows {
    text-align: center; }
    .avatar-showcase .pepole-knows ul li {
      margin-right: 14px; }
  .social-app-profile .hovercard .user-image .avatar {
    margin-top: -56px; }
    .social-app-profile .hovercard .user-image .avatar img {
      width: 100px;
      height: 100px; }
  .social-app-profile .hovercard .user-image .icon-wrapper {
    left: 52%;
    top: 10px;
    height: 30px;
    width: 30px;
    font-size: 15px; }
    .social-app-profile .hovercard .user-image .icon-wrapper:hover {
      font-size: 12px; }
  .social-app-profile .hovercard .user-image .share-icons {
    right: 15px; }
    .social-app-profile .hovercard .user-image .share-icons li {
      margin-right: 0px; }
  .social-app-profile .hovercard .info .tabs-scoial .user-designation {
    border-top: none;
    border-bottom: none;
    margin-bottom: 0;
    padding: 0px 0px; }
  .social-app-profile .tabs-scoial {
    margin-bottom: 0 !important; }
  .socialprofile .social-btngroup {
    margin: 15px 0; }
  .socialprofile .social-group {
    margin-top: 15px; }
  .social-status form .form-control {
    margin-bottom: 15px; }
  .social-status .media {
    margin-bottom: 15px; }
  .new-users-social {
    margin-bottom: 15px; }
  .tabs-scoial {
    position: relative;
    padding-top: 80px; }
    .tabs-scoial li:nth-child(3) {
      position: absolute;
      top: 25px;
      left: 0;
      right: 0;
      margin: 0 auto; }
    .tabs-scoial.border-tab.nav-tabs .nav-item .nav-link {
      padding: 10px 18px; }
  .timeline-content p, .timeline-content .comments-box {
    margin-top: 15px; }
  .timeline-content .comment-number i {
    margin-right: 15px; }
  .social-chat {
    margin-top: 15px; }
    .social-chat .media-body {
      padding: 15px; }
    .social-chat .your-msg, .social-chat .other-msg {
      margin-bottom: 15px; }
  .social-network span {
    margin-bottom: 15px; }
  .social-list .media {
    margin-bottom: 15px; }
  .details-about + .details-about {
    margin-top: 15px; }
  .your-details-xs {
    margin-top: 15px; }
  .social-header h5 span.pull-right {
    float: right !important;
    margin-top: 0 !important; }
    .social-header h5 span.pull-right svg {
      width: 18px;
      height: 18px; }
  .activity-log .my-activity + .my-activity {
    margin-top: 15px; }
  .photos ul li {
    width: 26%; }
    .photos ul li:nth-child(3n) {
      margin-right: 0; }
  .timeline-small .media {
    margin-bottom: 15px; }
    .timeline-small .media .media-body h6 {
      margin-bottom: 15px; }
    .timeline-small .media .timeline-round.timeline-line-1:after {
      bottom: -42px !important; }
    .timeline-small .media .timeline-round.small-line:after {
      bottom: -17px;
      height: 8px; }
    .timeline-small .media .timeline-round.medium-line:after {
      bottom: -47px;
      height: 34px; }
  .landing-main .landing-home .landing-body img {
    width: 66%; }
  .landing-main .navbar-expand-xl {
    padding-left: 10px; }
    .landing-main .navbar-expand-xl .navbar-brand {
      padding-left: 0; }
  .landing-main h4 {
    font-size: 17px; }
  .landing-main .section-space .landing-header h2 {
    font-size: 20px; }
  .landing-main .section-space .landing-header .line {
    margin-bottom: 40px; }
  .landing-main .footer-bg h2 {
    font-size: 21px; }
  .landing-main .footer-bg .star-rate {
    margin: 20px 0; }
    .landing-main .footer-bg .star-rate i {
      font-size: 30px; }
  .landing-main .footer-bg button {
    font-size: 18px; }
  .helper-classes {
    padding: 15px; }
  .form-builder .pl-md-radios {
    padding-left: 30px; }
  .form-builder-2-header ul li {
    text-align: left; }
  .form-builder-2-header .form-inline {
    display: block;
    width: 100%; }
    .form-builder-2-header .form-inline .form-group {
      width: 100%;
      margin-right: 0; }
    .form-builder-2-header .form-inline button {
      width: 100%;
      text-align: left; }
  .page-builder .btn-grid {
    margin-bottom: 7px;
    padding-right: 5px; }
  .page-builder .ge-canvas.ge-editing .row {
    padding: 15px; }
  .page-builder .ge-canvas.ge-editing .ge-tools-drawer {
    margin: 10px; }
  .page-builder .ge-canvas.ge-layout-desktop [class*="col-"] {
    width: inherit !important; }
  .wizard-4 .step-container div.content {
    margin-top: 10px; }
  .nav-tabs .nav-item.show .nav-link {
    border-top: 0;
    border-bottom: 0; }
  .nav-tabs .nav-link {
    border-top: 0;
    border-bottom: 0; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link.active, .nav-tabs .nav-link:focus {
      border-top: 0;
      border-bottom: 0; }
  .border-tab.nav-tabs {
    display: block; }
    .border-tab.nav-tabs .nav-item {
      width: 100% !important; }
  .border-tab .weather-widget-two .bottom-whetherinfo .whether-content {
    top: 35px; }
  .border-tab .social-widget-card .b-b-light {
    padding: 15px;
    margin-bottom: 15px; }
  .border-tab .ecommerce-widget .progress-showcase {
    margin-top: 15px; }
  .nav {
    display: inherit;
    text-align: center;
    border-bottom: none; }
  .border-tab.nav-left .nav-link {
    text-align: center;
    border-left: none; }
  .border-tab.nav-right .nav-link {
    text-align: center;
    border-right: none; }
  .border-tab.nav-tabs {
    margin-bottom: 10px; }
  .tab-content ~ .nav-tabs.border-tab {
    margin-top: 10px; }
  .nav-pills {
    text-align: center; }
  .crm-overall {
    margin: 0 -15px -15px; }
  .product-page-main {
    padding: 15px; }
    .product-page-main .m-t-15 {
      margin-top: 0 !important; }
      .product-page-main .m-t-15 .btn {
        margin-top: 10px; }
  .tabbed-card ul {
    padding: 10px 0 5px 0;
    position: relative;
    width: 100%;
    left: 0; }
    .tabbed-card ul.border-tab {
      margin-bottom: 15px; }
      .tabbed-card ul.border-tab.nav-tabs .nav-item .nav-link {
        padding: 5px 15px 10px; }
  .todo .action-box.large {
    height: 25px;
    width: 25px; }
    .todo .action-box.large .icon {
      font-size: 14px;
      vertical-align: -3px; }
  .todo .todo-list-wrapper #todo-list li .task-container .task-label {
    font-size: 14px; }
  .todo .todo-list-wrapper .mark-all-tasks {
    top: 15px;
    right: 15px; }
  .datetime-picker label {
    text-align: left !important; }
  .redial-chart-block {
    text-align: center; }
  #nav-tabContent {
    margin-top: 15px; }
  .reset-password-box {
    width: 430px; }
  .auth-bg {
    padding: 25px 15px; }
  .auth-bg-effect {
    display: none; }
  .date-picker .theme-form > .row > div:last-child {
    margin-top: 0; }
  .dataTables_wrapper table.dataTable {
    margin-bottom: 15px !important; }
  ul.pagination li:before {
    line-height: 3; }
  div.table-responsive > div.dataTables_wrapper > div.row > div[class^="col-"]:last-child {
    padding-left: 0 !important; }
  div.table-responsive > div.dataTables_wrapper > div.row > div[class^="col-"]:first-child {
    padding-right: 0 !important; }
  div.dataTables_wrapper div.dataTables_length {
    margin-bottom: 15px; }
    div.dataTables_wrapper div.dataTables_length select {
      min-height: 30px; }
  div.dataTables_wrapper table.dataTable th, div.dataTables_wrapper table.dataTable td {
    padding: 0.5rem; }
  div.dataTables_wrapper div.dataTables_paginate {
    margin-top: 15px !important; }
    div.dataTables_wrapper div.dataTables_paginate .paginate_button {
      padding: 1px 7px; }
      div.dataTables_wrapper div.dataTables_paginate .paginate_button.previous {
        font-size: 0;
        padding: 0; }
        div.dataTables_wrapper div.dataTables_paginate .paginate_button.previous:before {
          content: "\e64a";
          font-family: themify;
          font-size: 11px;
          padding: 0 5px; }
      div.dataTables_wrapper div.dataTables_paginate .paginate_button.next {
        font-size: 0;
        padding: 0; }
        div.dataTables_wrapper div.dataTables_paginate .paginate_button.next:before {
          content: "\e649";
          font-family: themify;
          font-size: 11px;
          padding: 0 5px; }
  div.dataTables_wrapper .dataTables_filter {
    margin-bottom: 15px; }
    div.dataTables_wrapper .dataTables_filter input[type="search"] {
      height: 30px; }
  .page-wrapper .page-body-wrapper .user-profile .profile-img-style {
    padding: 15px; }
    .page-wrapper .page-body-wrapper .user-profile .profile-img-style .img-container {
      margin-top: 15px; }
  .page-wrapper .page-body-wrapper .user-profile hr {
    margin: 15px 0; }
  .page-wrapper .page-body-wrapper .user-profile .like-comment {
    margin-top: 15px; }
  .jvector-map-height {
    height: 250px; }
  .user-profile .order-sm-0 {
    order: -1; }
  .user-profile .hovercard .info .ttl-info {
    text-align: center !important; }
  .user-profile .hovercard .info .ttl-xs-mt {
    margin-top: 20px; }
  .icon-hover-bottom .icon-popup {
    padding: 15px; }
    .icon-hover-bottom .icon-popup .form-group input {
      width: 100%; }
    .icon-hover-bottom .icon-popup .form-group .btn {
      margin-top: 10px; }
  .icon-hover-bottom svg.climacon {
    height: 50px;
    width: 50px; }
  .blog-box.blog-shadow:before {
    box-shadow: inset 0px -100px 100px -13px rgba(0, 0, 0, 0.6); }
  .blog-box .blog-details p, .blog-box .blog-details .single-blog-content-top {
    margin-top: 15px; }
  .blog-box .blog-details h4 {
    margin-top: 15px; }
  .blog-box .blog-details .blog-social {
    margin-top: 15px; }
    .blog-box .blog-details .blog-social li + li {
      padding-left: 20px; }
      .blog-box .blog-details .blog-social li + li > span {
        display: none; }
    .blog-box .blog-details .blog-social li:first-child {
      margin-bottom: 10px;
      padding-right: 20px; }
  .comment-box {
    padding-top: 40px;
    padding-bottom: 40px; }
    .comment-box ul ul {
      margin-left: 30px; }
    .comment-box li .media {
      display: block; }
      .comment-box li .media img {
        height: 50px;
        width: 50px;
        padding: 3px;
        margin-bottom: 10px; }
  .card .card-header .card-header-right {
    top: 8px;
    right: 7px; }
  .card .server-header .card-header-right {
    top: 9px; }
  .ace-editor {
    height: 320px; }
  .gallery > a {
    margin-bottom: 15px;
    text-align: center; }
  .card .card-header h5 {
    font-size: 17px; }
  .theme-form .form-group {
    margin-bottom: 15px; }
  .page-wrapper .page-body-wrapper .default-according .card .card-header, .page-wrapper .page-body-wrapper .default-according .card .card-body, .page-wrapper .page-body-wrapper .default-according .card .card-footer {
    padding: 0.75rem 1.25rem; }
  .page-wrapper .page-body-wrapper .card {
    margin-bottom: 15px; }
    .page-wrapper .page-body-wrapper .card .card-header, .page-wrapper .page-body-wrapper .card .card-body, .page-wrapper .page-body-wrapper .card .card-footer {
      padding: 15px; }
      .page-wrapper .page-body-wrapper .card .card-header .tab-content .m-t-30, .page-wrapper .page-body-wrapper .card .card-body .tab-content .m-t-30, .page-wrapper .page-body-wrapper .card .card-footer .tab-content .m-t-30 {
        margin-top: 15px !important; }
      .page-wrapper .page-body-wrapper .card .card-header .tab-content .m-b-30, .page-wrapper .page-body-wrapper .card .card-body .tab-content .m-b-30, .page-wrapper .page-body-wrapper .card .card-footer .tab-content .m-b-30 {
        margin-bottom: 15px !important; }
  .page-wrapper .page-body-wrapper .page-body {
    padding: 0 !important; }
  .page-wrapper .page-body-wrapper .page-header {
    padding-top: 20px;
    padding-bottom: 20px; }
    .page-wrapper .page-body-wrapper .page-header .row h3 {
      font-size: 20px; }
  .page-wrapper .page-main-header .main-header-right .nav-right > ul {
    padding: 0 0; }
    .page-wrapper .page-main-header .main-header-right .nav-right > ul .search-form .mobile-search svg {
      color: #4466f2; }
    .page-wrapper .page-main-header .main-header-right .nav-right > ul .search-form .form-control-plaintext {
      top: 60px;
      position: absolute;
      transition: all linear 0.3s;
      left: 0;
      background-color: #fff;
      transform: translateY(-35px) scaleY(0);
      opacity: 0;
      visibility: hidden;
      width: 180px;
      padding: 10px 10px 10px 15px; }
      .page-wrapper .page-main-header .main-header-right .nav-right > ul .search-form .form-control-plaintext.open {
        transform: translateY(0px) scaleY(1);
        opacity: 1;
        visibility: visible;
        transition: all linear 0.3s; }
    .page-wrapper .page-main-header .main-header-right .nav-right > ul .search-form .form-group {
      margin-bottom: 0; }
      .page-wrapper .page-main-header .main-header-right .nav-right > ul .search-form .form-group:before, .page-wrapper .page-main-header .main-header-right .nav-right > ul .search-form .form-group:after {
        display: none; }
    .page-wrapper .page-main-header .main-header-right .nav-right > ul > li:first-child {
      width: auto;
      margin: 0; }
  .page-wrapper .search-form .form-group {
    margin-right: 0; }
  .search-form .form-group:before {
    top: 26px; }
  .search-form .form-group:after {
    top: 29px; }
  /* alert responsive css start here */
  .alert-primary.inverse {
    background-color: transparent; }
  .alert-secondary.inverse {
    background-color: transparent; }
  .alert-success.inverse {
    background-color: transparent; }
  .alert-danger.inverse {
    background-color: transparent; }
  .alert-warning.inverse {
    background-color: transparent; }
  .alert-info.inverse {
    background-color: transparent; }
  .alert-light.inverse {
    background-color: transparent; }
  .alert-dark.inverse {
    background-color: transparent; }
  .alert .close {
    height: 100%; }
  .calender-widget .cal-desc {
    padding: 10px 15px 15px !important; }
    .calender-widget .cal-desc p {
      max-height: 100%; }
  .user-profile hr {
    margin: 15px 0; }
  .user-profile .hovercard .cardheader {
    height: 300px; }
  .user-profile .hovercard .info {
    padding: 20px; }
    .user-profile .hovercard .info .user-designation {
      border-top: 1px solid #f6f7fb;
      border-bottom: 1px solid #f6f7fb;
      margin-bottom: 15px;
      padding: 15px 0px; }
  .user-profile .hovercard .social-media a {
    margin-right: 0px;
    font-size: 16px; }
  .user-profile .hovercard .follow .follow-num {
    font-size: 20px; }
  .user-profile .profile-img-style .pictures img {
    margin-bottom: 10px; }
  .custom-card .card-footer > div h6 {
    font-size: 14px;
    font-weight: 600; }
  .custom-card .card-footer > div h3 {
    font-size: 24px; }
  .calendar-wrap .fc-toolbar .fc-center {
    margin-top: 10px; }
  .calendar-wrap a.fc-more {
    font-size: .70em; }
  .mega-inline {
    display: block; }
  .megaoptions-border-space-sm {
    padding-bottom: 30px !important; }
  .blog-box.blog-shadow .blog-details {
    padding: 15px; }
    .blog-box.blog-shadow .blog-details p {
      margin-bottom: 0; }
    .blog-box.blog-shadow .blog-details h4 {
      margin-bottom: 10px; }
  .blog-box.blog-list .blog-details {
    padding-top: 0; }
  .blog-box .blog-details {
    padding: 20px; }
  .blog-box .blog-date span {
    font-size: 25px; }
  .blog-single .blog-box .blog-details {
    padding: 0; }
  .error-wrapper {
    padding: 20px 0; }
    .error-wrapper .btn {
      margin-top: 15px; }
    .error-wrapper .img-100 {
      width: 70px !important; }
    .error-wrapper .error-heading {
      margin-top: 20px; }
      .error-wrapper .error-heading .cloud-second {
        margin-top: -60px; }
      .error-wrapper .error-heading .headline {
        font-size: 150px; }
    .error-wrapper .maintenance-heading .cloud-second {
      display: none; }
    .error-wrapper .maintenance-heading .headline {
      font-size: 25px;
      margin-top: -10%;
      letter-spacing: 2px; }
  .comingsoon .comingsoon-inner .coming-soon-bottom-link {
    margin-top: 20px;
    line-height: 1; }
  .comingsoon .comingsoon-inner h5 {
    margin-bottom: 20px;
    margin-top: 20px; }
  .comingsoon .comingsoon-inner .countdown {
    padding: 20px 0px; }
    .comingsoon .comingsoon-inner .countdown ul li {
      margin: 0 7px; }
    .comingsoon .comingsoon-inner .countdown .title {
      font-size: 12px; }
    .comingsoon .comingsoon-inner .countdown .time {
      width: 45px;
      height: 45px;
      line-height: 45px;
      font-size: 16px;
      margin: 0 auto; }
  .form-wizard .btn-mb {
    margin-bottom: 15px; }
  .stepwizard {
    margin-bottom: 15px; }
  .f1 .f1-steps {
    margin-top: 15px;
    margin-bottom: 15px; }
  .wizard-4 ul.anchor {
    width: 100%;
    padding-bottom: 15px;
    padding-right: 0; }
  .wizard-4 .step-container {
    width: 100%; }
  .smooth-chart.flot-chart-container, .flot-chart-container {
    height: 300px; }
  .small-chart-widget .chart-container {
    height: 134px; }
  .status-widget .text-sm-right {
    text-align: right; }
  .mega-menu {
    padding-left: 15px; }
    .mega-menu .onhover-show-div {
      height: 405px;
      left: 0;
      top: 61px;
      padding: 15px; }
    .mega-menu .lg-mt {
      margin-top: 10px; }
    .mega-menu .xs-mt {
      margin-top: 15px; }
  .nav-md-mt {
    margin-top: 15px; }
  .navs-icon {
    padding: 15px; }
    .navs-icon .main-section {
      padding-top: 15px; }
    .navs-icon .separator {
      margin: 15px 0; }
  .nav-list {
    padding: 15px; }
  .navs-dropdown button {
    margin-top: 15px; }
  .navs-dropdown .onhover-show-div {
    top: 64px; }
  .lg-mt {
    margin-top: 15px; }
  #aniimated-thumbnials a:last-child img {
    margin-bottom: 15px; }
  .tilt-showcase .mt-4 {
    margin-top: 15px !important; }
  .tilt-showcase ol .m-b-20 {
    margin-bottom: 15px !important; }
  .xs-mt {
    margin-top: 15px; }
  span:last-child.tag-pills-sm-mb {
    margin-top: 3px; }
  .alert-dismissible p {
    max-width: 209px; }
  .animated-modal .form-group .form-control {
    width: 50%;
    margin: 0 auto; }
  .animated-modal .animated-modal-md-mb {
    margin-bottom: 15px !important; }
  .steps-md-mt {
    margin-top: 15px; }
  .steps-sizing-sm-mb {
    margin-bottom: 15px; }
  .bootstrap-touchspin .touchspin-vertical-tab .input-group {
    width: 96% !important; }
  #API-chield-row_wrapper #API-chield-row tbody tr td:first-child {
    min-width: 20px; }
  .chart-block .flot-chart-container p#choices {
    width: 100%; }
  .chart-block .flot-chart-container #toggling-series-flot {
    width: 100% !important; }
  .click2edit ~ .note-editor.note-frame {
    margin-bottom: 15px; }
  .chat-box .chat-history .call-content > div {
    padding-top: 60px; }
  .chat-box .chat-left-aside .people-list {
    height: auto; }
  .call-chat-sidebar {
    max-width: 100%;
    width: 100%;
    flex-basis: auto; }
    .call-chat-sidebar .people-list {
      height: auto; }
  .chat-left-aside .people-list ul {
    max-height: 100px; }
  .ecommerce-widget .progress-showcase {
    margin-top: 15px; }
  .reset-password-box .theme-form .btn {
    margin-top: 13px !important; }
  .gallery-with-description a > div {
    margin-bottom: 15px; }
  .gallery-img-mb-sm {
    margin-bottom: 15px; }
  .page-wrapper.document .landing-main .navbar-expand-lg .navbar-brand {
    width: 150px; }
    .page-wrapper.document .landing-main .navbar-expand-lg .navbar-brand img {
      width: unset; }
  .page-wrapper.document .page-body-wrapper.document-content .page-sidebar {
    top: 80px; }
  .page-main-header .main-header-right {
    padding: 0 15px; }
  .xm-mb-peity {
    margin-bottom: 8px; }
  .authentication-main {
    padding: 30px 15px; } }

/* ========= responsive scss for 480 screen ========= */
@media only screen and (max-width: 480px) {
  .order-box .sub-total .shipping-class .shopping-checkout-option {
    width: 48%; }
  .order-box .sub-total li .count {
    width: 48%; }
  .order-box .total li .count {
    width: 48%; }
  .github-chart {
    height: 350px; }
  .page-wrapper .page-body-wrapper .page-header .row h3 {
    font-size: 18px; }
  .cd-container {
    margin-right: 0; }
  #linecharts-bitcoin {
    height: 270px !important; }
  .creative-demo-section .creative-demo-content .couting h2 {
    padding-left: 20px; }
  .ptb50 {
    padding: 50px 0; }
  .and-many-more {
    font-size: 45px; }
  .and-more {
    font-size: 20px; }
  .landing-center .footer-content h1 {
    font-size: 17px; }
  .footer-pattern-root:before {
    position: absolute;
    content: '';
    width: 10px;
    height: 4px;
    background-color: white;
    border-radius: 5px;
    left: calc(53% - -6px); }
  .footer-pattern-root:after {
    position: absolute;
    content: '';
    width: 10px;
    height: 4px;
    border-radius: 5px;
    background-color: white;
    right: calc(53% - -6px); }
  .landing-center .list-inline .vl:before {
    right: 75px;
    top: 6px;
    height: 30px; }
  .landing-center .list-inline .vl:after {
    left: 75px;
    top: 6px;
    height: 30px; }
  .landing-center .footer-content h1 {
    font-size: 15px; }
  .landing-center .title .list-inline-item img {
    width: 70%; }
  .landing-center .list-inline .list-inline-item {
    margin: 0 3px; }
  .round_circle1_bg_sky .couting h2 {
    font-size: 22px; }
  .creative-demo-content .couting h2 {
    font-size: 22px; }
  .round-tringle-square-animation {
    display: none; }
  .gallery-img-blur-div {
    height: 70px; }
  .bg-gallery {
    min-height: 390px; }
  .landing_gallery_section_img:before {
    height: 350px; }
  .landing_gallery_section_img {
    margin-bottom: -80px; }
  .bg-sky-blue {
    height: 450px; }
  .landing-center .landing_first_section_img .img-set3 {
    display: none; }
  .landing-center .img-wave1-set {
    height: 14%; }
  .landing_first_section_img {
    width: 71% !important; }
  .landing-center .title-content h1 {
    font-size: 20px; }
  .landing-center .title-content p {
    font-size: 12px; }
  .landing-center .landing_first_section_img .img-set1 {
    top: 80px !important; }
  .landing-center .landing_first_section_img .img-set2 {
    top: 80px !important; }
  .creative-demo-section .creative-demo-content p {
    font-size: 13px; }
  .bg-gallery .gallery_content p {
    font-size: 13px; }
  .container-modify .cretive_img_content h4 {
    font-size: 20px; }
  .box-layout.page-wrapper .set-col-6, .box-layout.page-wrapper .set-col-3, .box-layout.page-wrapper .set-col-7, .box-layout.page-wrapper .set-col-5 {
    flex: 0 0 100%;
    max-width: 100%; }
  .wizard-4 .action-bar .btn {
    width: 100%; }
    .wizard-4 .action-bar .btn + .btn {
      margin-top: 10px; }
  .inline-block-sm {
    display: inline-block; }
  .pagination-lg .page-link {
    padding: 0.3rem 0.65rem;
    font-size: 18px; }
  .nav-pills li {
    width: 100%; }
  .reset-password-box {
    width: 290px; }
  .icon-hover-bottom .icon-first {
    margin-right: 10px; }
  .icon-hover-bottom .icon-popup .icon-class {
    display: none; }
  .error-wrapper .maintenance-heading {
    margin-top: 0; }
  .card .card-header > span + span {
    display: none; }
  .custom-card {
    padding: 0; }
    .custom-card .card-footer > div h3 {
      font-size: 20px; }
    .custom-card .card-social li {
      padding: 5px 0; }
    .custom-card .profile-details h6 {
      margin-bottom: 15px; }
    .custom-card .profile-details h4 {
      font-size: 20px; }
    .custom-card .card-profile img {
      height: 93px; }
  .card .card-header .card-header-right i {
    margin: 0 3px;
    font-size: 14px; }
  .blog-box.blog-shadow .blog-details {
    padding: 10px; }
    .blog-box.blog-shadow .blog-details h4 {
      font-size: 14px; }
  .cal-date-widget {
    padding: 30px; }
  .calender-widget .cal-img {
    height: 225px; }
  .company-card .details .media {
    flex-wrap: wrap; }
  .stepwizard .stepwizard-row:before {
    top: 17px; }
  .stepwizard .stepwizard-row .stepwizard-step .btn {
    padding: 6px 22px; }
  .email-wrap .email-right-aside .email-content .email-wrapper .attachment ul li img {
    width: 83px;
    height: 83px; }
  .mega-menu {
    padding-left: 20px;
    padding-top: 3px; }
    .mega-menu .onhover-show-div {
      height: 405px;
      left: 0;
      top: 61px;
      padding: 15px; }
    .mega-menu .lg-mt {
      margin-top: 10px; }
    .mega-menu .xs-mt {
      margin-top: 15px; }
  .badge {
    padding: 0.42em 0.7em; }
  .alert-dismissible .close {
    top: -1px; }
    .alert-dismissible .close span {
      font-size: 19px; }
  .invoice .text-md-end {
    margin-top: 10px; } }

/* ========= responsive scss for 420 screen ========= */
@media only screen and (max-width: 420px) {
  .timeliny .timeliny-dot::after {
    width: 190px; }
  .alert-dismissible {
    padding-right: 30px; }
  .product-sidebar.open {
    top: -75px; }
  .feature-products .text-end {
    text-align: left !important; }
    .feature-products .text-end span {
      margin-bottom: 10px; }
  .bg-gallery {
    min-height: 350px; }
  .footer-bg .footer-content .btn {
    font-size: 12px;
    letter-spacing: 1.2px; }
  .and-many-more {
    font-size: 37px; }
  .and-more {
    font-size: 18px;
    top: 35%; }
  .footer-pattern-root .footer-line-pattern {
    width: 25px; }
  .footer-pattern-root:before {
    position: absolute;
    content: '';
    width: 9px;
    height: 4px;
    left: calc(54%); }
  .footer-pattern-root:after {
    position: absolute;
    content: '';
    width: 9px;
    height: 4px;
    right: calc(54%); }
  .landing-center .footer-content h1 {
    font-size: 14px;
    margin-bottom: 10px; }
  .landing-center .footer-content p {
    font-size: 12px; }
  .page-wrapper .section-space {
    padding: 30px 0; }
  .creative-demo-content h1 {
    font-size: 40px; }
  .landing-center .list-inline .list-inline-item {
    margin: 0 -5px; }
  .landing-center .title .list-inline-item img {
    width: 65%; }
  .landing-center .title {
    width: 100vw;
    margin-left: -22px; }
  .bg-sky-blue {
    height: 420px; }
  .landing_first_section_img .img-set2 {
    top: 50px !important; }
    .landing_first_section_img .img-set2 img {
      width: 30%; }
  .landing_first_section_img .img-set1 {
    top: 50px !important; }
    .landing_first_section_img .img-set1 img {
      width: 30%; }
  .ptb50 {
    padding: 50px 0; }
  .landing-center .list-inline .vl:before {
    right: 70px;
    top: 6px; }
  .landing-center .list-inline .vl:after {
    left: 70px;
    top: 6px; }
  .landing-center .title-content h1 {
    font-size: 17px;
    line-height: 1.7; }
  .round_circle1_bg_sky .couting:before {
    width: 28px;
    height: 28px;
    top: -40px;
    left: 0; }
  .round_circle1_bg_sky .couting h2 {
    font-size: 22px; }
    .round_circle1_bg_sky .couting h2:before {
      width: 40px;
      height: 40px;
      left: -40px;
      border: 8px solid #fb628; }
    .round_circle1_bg_sky .couting h2:after {
      width: 20px;
      height: 20px;
      left: -30px;
      top: -40px;
      border: 5px solid #38b3f3; }
  .creative-demo-section .creative-demo-content .couting h2 {
    padding-left: 10px; }
  .creative-demo-content .couting:before {
    width: 28px;
    height: 28px;
    top: -40px;
    left: 0; }
  .creative-demo-content .couting h2 {
    font-size: 18px; }
    .creative-demo-content .couting h2:before {
      width: 40px;
      height: 40px;
      left: -40px;
      border: 8px solid #fb628; }
    .creative-demo-content .couting h2:after {
      width: 20px;
      height: 20px;
      left: -30px;
      top: -40px;
      border: 5px solid #2b8ff4; } }

@media only screen and (max-width: 397px) {
  .clockpicker-align-top {
    top: 426px !important; } }

/* ========= responsive scss for 360 screen ========= */
@media only screen and (max-width: 360px) {
  .knob-chart .chart-clock-main .clock-large {
    left: -26px; }
    .knob-chart .chart-clock-main .clock-large canvas {
      width: 250px !important;
      height: 250px !important; }
  .knob-chart .chart-clock-main .clock-medium {
    left: 27px;
    top: 7px; }
    .knob-chart .chart-clock-main .clock-medium canvas {
      width: 150px !important;
      height: 150px !important; }
  .knob-chart .chart-clock-main .clock-small {
    left: 68px;
    top: 49px; }
    .knob-chart .chart-clock-main .clock-small canvas {
      width: 70px !important;
      height: 70px !important; }
  .tron-xs canvas {
    width: 250px !important;
    height: 250px !important; }
  .bottom-content .block-bottom {
    font-size: 13px; }
  .widget-joins .row div:last-child .media .media-body:last-child {
    margin-left: -12px; }
  .widget-joins .row div:nth-child(2) .media .media-body:last-child {
    margin-left: -23px; }
  .product-wrapper-grid.list-view .product-box {
    display: block; }
    .product-wrapper-grid.list-view .product-box .product-img {
      width: 100%; }
  .customizer-links.open {
    right: 228px; }
  .customizer-contain {
    width: 228px; }
    .customizer-contain .customizer-body .nac-pills {
      display: inherit; }
      .customizer-contain .customizer-body .nac-pills .nav-item {
        width: 100%; }
    .customizer-contain .customizer-body .main-layout > li {
      display: inherit;
      margin: 0 auto; }
      .customizer-contain .customizer-body .main-layout > li + li {
        margin-top: 15px;
        margin-left: auto; }
    .customizer-contain .customizer-body .sidebar-type > li, .customizer-contain .customizer-body .sidebar-setting > li {
      width: 80px; }
      .customizer-contain .customizer-body .sidebar-type > li + li + li, .customizer-contain .customizer-body .sidebar-setting > li + li + li {
        margin-top: 15px; }
  .bg-gallery {
    min-height: 320px; }
  .footer-pattern-root:before {
    left: calc(55% - 1px); }
  .footer-pattern-root:after {
    right: calc(55% - 1px); }
  .and-many-more {
    font-size: 30px; }
  .landing-center .list-inline .list-inline-item {
    margin: 0 -8px; }
  .landing-center .list-inline .vl:before {
    right: 67px;
    top: 6px; }
  .landing-center .list-inline .vl:after {
    left: 67px;
    top: 6px; }
  .creative-demo-content span h2 {
    font-size: 20px; }
  .gallery-img-blur-div {
    height: 50px; }
  .components-section .component-col-set {
    max-width: 50%; }
  .bg-gallery .gallery_content p {
    font-size: 12px; }
  .add-post form .m-checkbox-inline label {
    margin-bottom: 8px; }
    .add-post form .m-checkbox-inline label:last-child {
      margin-bottom: 0; }
  .map-js-height {
    height: 250px; }
  .alert-theme {
    font-size: 12px; }
    .alert-theme button {
      right: 30px !important; }
    .alert-theme i {
      display: none; }
    .alert-theme span + span + span {
      padding: 15px; }
    .alert-theme [data-notify='message'] {
      width: 100%; }
  .bar-chart-widget .earning-details i {
    right: -73px; }
  .widget-joins .border-after-xs:after {
    width: 92%; }
  .page-wrapper .page-main-header .main-header-left .logo-wrapper img {
    margin-top: 5px; }
  .page-wrapper .page-main-header .main-header-right .nav-right > ul > li {
    padding: 0 13px; }
    .page-wrapper .page-main-header .main-header-right .nav-right > ul > li .dot {
      right: 14px; }
  .page-wrapper .page-main-header .main-header-right .nav-right .profile-dropdown {
    right: 6px; }
  .status-widget svg {
    width: 18px;
    height: 18px; }
  .custom-card .card-footer > div h6 {
    font-size: 12px; }
  .language-header {
    padding-bottom: 40px !important; }
  .main .langChoice {
    padding: 4px 7px;
    left: 0;
    margin: 0 auto;
    top: 37px;
    width: 30%;
    right: 0; }
  .tabs-scoial.border-tab.nav-tabs .nav-item .nav-link {
    padding: 10px 10px;
    font-size: 14px; }
  .user-profile .hovercard .user-image .share-icons {
    right: 6px;
    top: -15px; }
    .user-profile .hovercard .user-image .share-icons li .social-icon {
      width: 25px;
      height: 25px; }
      .user-profile .hovercard .user-image .share-icons li .social-icon i {
        font-size: 14px; }
  .social-chat .other-msg {
    margin-left: 15px; }
  .social-header h5 span.pull-right {
    display: none; }
  .timeline-small .media .timeline-round.timeline-line-1:after {
    bottom: -74px !important;
    height: 56px; }
  .timeline-small .media .timeline-round.small-line:after {
    bottom: -32px;
    height: 14px; }
  .timeline-small .media .timeline-round.medium-line:after {
    bottom: -59px;
    height: 40px; }
  .custom-card .dashboard-card {
    padding-top: 0; }
  .custom-card .card-profile img {
    bottom: 25px; }
  .smooth-chart.flot-chart-container, .flot-chart-container {
    height: 230px; }
  .map-block {
    height: 250px; }
  .img-cropper .docs-tooltip {
    font-size: 11px; }
  .img-cropper .docs-toggles .btn-group .btn {
    padding: 0.375rem 0.2rem; }
  .blog-box .blog-details p {
    line-height: 1.5; }
  .blog-box .blog-details-main .blog-social li {
    padding: 0 10px; }
  .button-builder-wrap .btn-lg {
    font-size: 18px; }
  /* responsive material tabs*/
  .border-tab.nav-tabs .nav-item .nav-link {
    padding: 10px 0px; }
  .tabs-responsive-side {
    max-width: 100%; }
  .cal-date-widget .datepicker {
    width: unset; }
  .browser-widget .media i {
    font-size: 52px; }
  .browser-widget .media .media-body h4 {
    font-size: 20px; }
  .bar-chart-widget .bottom-content .num {
    font-size: 20px; }
  .stepwizard .stepwizard-row .stepwizard-step .btn {
    padding: 6px 20px; }
  .user-status table tbody img {
    height: 40px; }
  .xs-width-100 {
    min-width: 100%; }
  .email-wrap .action-wrapper .actions li {
    margin-right: 13px; }
  .email-wrap .email-right-aside .email-content .email-wrapper .attachment ul li img {
    width: 65px;
    height: 65px; }
  .email-wrap .email-right-aside .email-top .dropdown-menu {
    left: -58px; }
  .form-builder #components .component .form-group #button1id {
    margin-bottom: 15px; }
  .mega-menu-p {
    padding: 0 23px !important; }
  .default-according .card .card-header i {
    font-size: 14px;
    top: 24px; }
  .default-according .card .btn-link {
    display: block;
    width: 100%;
    padding-left: 17px; }
  .star-ratings .stars .title {
    line-height: 1; }
  .user-profile .hovercard .social-media a {
    padding: 0 12px; }
  .bootstrap-touchspin .touchspin-vertical-tab .input-group {
    width: 93% !important; }
  .step-container {
    height: 280px !important; }
  .sort-panel .d-inline .btn {
    margin-left: 87px; }
  .jsgrid .jsgrid-pager-container .jsgrid-pager .jsgrid-pager-page {
    padding: 0.5em 0.62em; }
  .chat-box .chat-right-aside {
    flex: 0 0 96%;
    max-width: 96%; }
  .error-wrapper .error-heading .headline {
    font-size: 100px; }
  .lg-toolbar .lg-icon {
    width: 40px; }
  .calendar-wrap a.fc-more {
    font-size: .59em; }
  .calendar-wrap .fc th {
    font-size: 10px; }
  .modal-footer button {
    padding: 5px 12px; } }

/**=====================
     62. Responsive CSS Ends
==========================**/
